/* FONT PATH
 * -------------------------- */

@font-face {
  font-family: "em-app-icon";
  src: url('#{$emIconFontPath}/em-app-icon.eot?#{$emIconFontVersion}'); /*ImageEmbed:skip*/
  src: url('#{$emIconFontPath}/em-app-icon.eot?#{$emIconFontVersion}#iefix') format('embedded-opentype'), /*ImageEmbed:skip*/
    url('#{$emIconFontPath}/em-app-icon.woff?#{$emIconFontVersion}') format('woff'), /*ImageEmbed:skip*/
    url('#{$emIconFontPath}/em-app-icon.ttf?#{$emIconFontVersion}') format('truetype'), /*ImageEmbed:skip*/
    url('#{$emIconFontPath}/em-app-icon.svg?#{$emIconFontVersion}#em-app-icon') format('svg'); /*ImageEmbed:skip*/
  font-weight: normal;
  font-style: normal;
}
