// This is a style for Vue.js based notifications indicator in main navigation
.notification-icon-indicator {
  margin: 0 7px 0 0;
  width: 13px;
  height: 13px;

  path {
    color: $gray40;
    fill: $gray40;
  }
}

.notification-icon-indicator.active {
  path {
    color: $brand-primary;
    fill: $brand-primary;
  }
}

// Indicator color on notifications page
.active .notification-icon-indicator.active,
.active .notification-icon-indicator {
  path {
    color: $component-active-color;
    fill: $component-active-color;
  }
}


// These are styles for Vue.js based notifications application

h4.height-as-h1 {
  margin-top: $line-height-computed;
  margin-bottom: ($line-height-computed / 2);
  line-height: $headings-line-height * 2.2;
  color: #999;

  a:hover {
    text-decoration: none;
  }
}

#notifications {
  border: 1px solid $table-border-color;
  padding: $padding-large-vertical $padding-large-horizontal;
  margin: $line-height-computed 0;

  tr.notification-item:hover,
  tr.notification-item.detailed {
    background-color: #fff;
  }
  tr.notification-item.detailed {
    border-top: 2px solid black;
  }

  a:hover,
  .clickable {
    cursor: pointer;
  }

  a.active {
    color: $link-hover-color;
    font-weight: bold;
  }

  // Show details of message transitions
  .fade-enter-active {
    transition: all .3s ease;
  }
  .fade-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .fade-enter, .fade-leave-to {
    transform: translateY(10px);
    opacity: 0;
  }

  .pagination {
    margin: 0;
    border-radius: none;
    a {
      background-color: transparent;
      border: none;
    }
    .active a {
      color: $link-hover-color;
      font-weight: bold;
    }
  }

  table > thead > tr {
    background-color: #eceded;
    font-weight: $weight-light;
    font-size: 14px;
    border-bottom: 1px solid #87888a;
  }

  table > thead > tr > th,
  table > tbody > tr > td,
  .table > thead > tr > th,
  .table > thead > tr > td {
    padding: 10px 15px;
  }

  .notification-item td:first-of-type {
    font-weight: $weight-light;
    font-size: 14px;
  }

  .notification-item {
    color: #87888a;

    &:hover {
      color: #000000;
    }

    td {
      vertical-align: top;
    }

    .toggle-action {
      text-align: center;
    }

    dl {
      position: relative;
      margin: 0;
    }

    dt, dd {
      font-weight: $weight-light;
      font-size: 16px;
      color: #000000;
    }

    dd {
      margin: $line-height-computed 0;
    }

    dl.closed dd {
      display: none;
      visibility: hidden;
    }
  }
}
