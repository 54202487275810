@mixin hatch($hatch_id,$color_id,$color_map) {
  #hatch_#{$hatch_id} {stroke: map-get($color_map,#{$color_id});}
}

@mixin media($type) {
    @if $type == xs {
        @media only screen and (max-width: 767px) {
            @content;
        }
    }
    @else if $type == sm {
        @media only screen and (min-width: 768px) {
            @content;
        }
    }
    @else if $type == md {
        @media only screen and (min-width: 992px) {
            @content;
        }
    }
    @else if $type == lg {
        @media only screen and (min-width: 1200px) {
            @content;
        }
    }
}

@mixin keyframes($animation) {
    @-webkit-keyframes #{$animation} {
      @content;
    }
    @-moz-keyframes #{$animation} {
      @content;
    }
    @-o-keyframes #{$animation} {
      @content;
    }
    @keyframes #{$animation} {
      @content;
    }
}

@mixin buttonBackground($start-color,$end-color) {
    background-color: $start-color;
    background-image: -moz-linear-gradient(top, $start-color, $end-color);
    background-image: -ms-linear-gradient(top, $start-color, $end-color);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from($start-color), to($end-color));
    background-image: -webkit-linear-gradient(top, $start-color, $end-color);
    background-image: -o-linear-gradient(top, $start-color, $end-color);
    background-image: linear-gradient(top, $start-color, $end-color);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$start-color', endColorstr='$end-color', GradientType=0);
    border-color: $end-color $end-color #edde34;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

@mixin button-precise-variant(
  $color,
  $bg-color,
  $border-color,
  $active-color,
  $active-bg-color,
  $active-border-color,
  $hover-color,
  $hover-bg-color,
  $hover-border-color,
  $hover-active-color,
  $hover-active-bg-color,
  $hover-active-border-color
) {
  color: $color;
  background-color: $bg-color;
  border-color: $border-color;

  &:hover,
  &:focus {
    color: $hover-color;
    background-color: $hover-bg-color;
        border-color: $hover-border-color;
  }
  &:active,
  &.active {
    color: $active-color;
    background-color: $active-bg-color;
        border-color: $active-border-color;
  }
  &:active:hover,
  &.active:hover {
    color: $hover-active-color;
    background-color: $hover-active-bg-color;
        border-color: $hover-active-border-color;
  }
  .open & { &.dropdown-toggle {
    color: $active-color;
    background-color: $active-bg-color;
        border-color: $active-border-color;
  } }
  &:active,
  &.active {
    background-image: none;
  }
  .open & { &.dropdown-toggle {
    background-image: none;
  } }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: $bg-color;
          border-color: $border-color;
    }
  }

  .badge {
    color: $bg-color;
    background-color: $color;
  }
}
