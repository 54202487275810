body {
    font-family: $font-family-sans-serif;
    font-weight: $weight-regular;

    font-variant-numeric: proportional-nums;
    -moz-font-feature-settings: "pnum" 1, "kern" 1;
    -ms-font-feature-settings: "pnum" 1, "kern" 1;
    -o-font-feature-settings: "pnum" 1, "kern" 1;
    -webkit-font-feature-settings: "pnum" 1, "kern" 1;
    font-feature-settings: "pnum" 1, "kern" 1;
}

.underline {
  text-decoration: underline;
}

.caret,
.btn .caret {
    margin-left: .25em;
}

.caret {
    border: none;
    display: inline-block;
    position: relative;
    width: auto;
    height: auto;

    &:before {
        @include icon-emIconFont();
        // font-size: 18px;
        // color: #989898;
        content: $emicon-o;
    }
}

.open .caret:before {
    content: $emicon-o2;
}

#content {
    margin-bottom: 120px;
}

.well {
    box-shadow: none;
}

.help-cursor { cursor: help; }

.alert-error {
    @extend .alert-danger;
}

#bar .user {
    display: none;
}

body.not-authenticated {
    background-color: #efefef;
    background-image: repeating-linear-gradient(135deg, rgba(255,255,255,1), rgba(255,255,255,1) 2px, rgba(255,255,255,1), transparent, transparent 2px, transparent 6px);
    padding-bottom: 60px;
}

.login {

    .form-signin-heading {
        color: #000000;
        font-size: 42px;
        font-weight: $weight-light;
        margin-top: ($line-height-computed / 2);
        margin-bottom: ($line-height-computed / 2);
    }

    label {
        color: #000000;
        font-size: 16px;
        font-weight: $weight-bold;
    }

    .forgot-passwd {
        font-size: 16px;
        font-weight: $weight-light;
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        line-height: $line-height-computed;
        padding: $padding-base-vertical $padding-base-horizontal;
        margin-top: ($line-height-computed / 2);
    }

    .btn-login {
        margin-top: ($line-height-computed / 2);
    }

    .action-register {
        text-decoration: underline;
    }
}

.login .well,
.not-authenticated .well {
    margin-top: 2em;
    background-color: $body-bg;
    border: 1px solid $gray30;
}

.not-authenticated #top-navigation {
    border: 1px solid $gray30;
}

#banner {
    margin-bottom: 0;
    padding-bottom: 10px;
    display: none;
}

#banner .alert {
    margin-bottom: 0;
}

html {
    position: relative;
    min-height: 100%;
}

footer {
    margin-top: 60px;
    border-top: 1px solid $gray20;
    position: absolute;
    bottom: 0;
    background-color: #FFFFFF;
    height: 60px;
    width: 100%;

    p {
        position: absolute;
        top: -1px;
        float: left;
        border-top: 1px solid $body-bg;
        padding: $padding-base-vertical $padding-base-horizontal $padding-base-vertical 0;

        &:before {
            content: "\20";
            position: absolute;
            top: -1px;
            left: -10px;
            width: 100%;
            background-color: $brand-primary;
            height: 1px;
            padding: 0;
            margin: 0;
            border-left: 10px solid $body-bg;
            border-right: 0px solid $body-bg;
        }
    }
}
