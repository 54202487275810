// Nope

.btn {
    font-weight: $weight-bold;
}

button[type=submit].btn {
    color: #FFFFFF;
    background-color: #57AB27;
    border-color: #57AB27;

  &:hover,
  &:focus {
    @include box-shadow(0px 2px rgba(0,0,0,.125));
  }
}

.btn-danger,
.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active {
    font-weight: $weight-regular;
    color: $link-color;
    border: 1px solid transparent;
    background-color: transparent;
    @include box-shadow(none);
}

.btn-close {
  @extend .btn-danger;
}

.btn-group.dropdown .btn.btn-default,
.btn.btn-default.choices-single {
  color: $gray40;
  font-weight: $weight-light;
  padding-left: 2em;
  @include box-shadow(none);
  border: 1px solid transparent;
}

.btn-group.dropdown {
  &.open .btn.dropdown-toggle {
    background-color: $dropdown-bg;
    border-radius: $border-radius-large $border-radius-large 0 0;
    @include media(xs) { border-radius: 0; }
    border-top: 1px solid $dropdown-border;
    border-right: 1px solid $dropdown-border;
    border-bottom: 1px solid transparent;
    border-left: 1px solid $dropdown-border;
    color: $gray40;
  }

  &.open .dropdown-menu {
      margin-top: 0;
      border-radius: 0;
  }

  .dropdown-menu > .active > a {
    &,
    &:hover,
    &:focus {
      font-weight: $weight-bold;
    }

    &:hover {
      color: $brand-primary;
      background-color: #FFFFFF;
    }
  }

}

.feeds-select {
  position: relative;

  &:before {
    @include icon-emIconFont();
    position: absolute;
    top: 0;
    left: 0.15em;
    font-size: 32px;
    color: $gray40;
    content: $emicon-l;
    z-index: 22;
    line-height: 1;
  }

  .btn-default {
    color: $gray40;
    border: 1px solid transparent;
    font-size: 18px;
  }
}

table td .btn {
  padding-top: 0;
  padding-bottom: 0;
}

table td a.btn [class^="icon-"] {
  display: inline;
  vertical-align: baseline;
}
a.btn [class^="icon-"] {
  display: inline-block;
  vertical-align: middle;
}

button:focus, .btn:focus,
button:active:focus, .btn:active:focus {
  outline: thin dotted;
}

.btn-disagree {
  font-weight: 600;
  color: #e2001a;
  border-color: #e2001a;
  background-color: transparent;

  &:hover {
    color: #e2001a;
    border-color: #8f0016;
  }
}
