.widget-dashboard-comparison {

    td em {
        color: #000000;
        font-style: normal;
        font-weight: $weight-light;
        display: inline-block;
        text-align: left;
        width: 33%;
        margin-left: 5px;
    }

    .primary {
        font-weight: $weight-black;

        @include media(xs) {
            font-size: 22px;
        }
        @include media(sm) {
            font-size: 46px;
        }
        @include media(md) {
            font-size: 62px;
        }

        em {
            @include media(xs) {
                font-size: 16px;
            }
            @include media(sm) {
                font-size: 24px;
            }
            @include media(md) {
                font-size: 32px;
            }
        }

        i {
            @include media(xs) {
                font-size: 14px;
            }
            @include media(sm) {
                font-size: 24px;
            }
            @include media(md) {
                font-size: 26px;
            }
        }
    }

    .secondary {
        font-weight: $weight-regular;
        color: #000000;

        @include media(xs) {
            font-size: 16px;
        }
        @include media(sm) {
            font-size: 24px;
        }
        @include media(md) {
            font-size: 36px;
        }

        em {
            @include media(xs) {
                font-size: 16px;
            }
            @include media(sm) {
                font-size: 24px;
            }
            @include media(md) {
                font-size: 32px;
            }
        }
    }

    .ternary {
        font-weight: $weight-black;

        @include media(xs) {
            font-size: 16px;
        }
        @include media(sm) {
            font-size: 24px;
        }
        @include media(md) {
            font-size: 36px;
        }

        em {
            @include media(xs) {
                font-size: 16px;
            }
            @include media(sm) {
                font-size: 24px;
            }
            @include media(md) {
                font-size: 32px;
            }
        }
    }

    td.info {
        color: $gray;
        font-weight: $weight-light;
        text-align: right;

        @include media(xs) {
            font-size: 12px;
            i {
                display: inline-block;
                visibility: visible;
            }
            span {
                display: none;
                visibility: hidden;
            }
        }
        @include media(sm) {
            font-size: 14px;
            i {
                display: none;
                visibility: hidden;
            }
            span {
                display: inline-block;
                visibility: visible;
            }
        }
        @include media(md) {
            font-size: 16px;
        }
    }

    td:nth-of-type(2) {
        text-align: right;
        white-space: nowrap;
    }

    table > tbody > tr > td {
        padding: 0 .25em;
        margin: 0;

        @include media(xs) {
            padding: 0;
        }
    }

    table > tbody > tr {
        border: 1px solid transparent;
    }

@each $medium_key, $medium_value in $medium {

    &.context-#{$medium_key} {

        .widget-header-icon {
            color: color($medium_key);

            &:before {
                @include unicode_content(medium($medium_key));
            }
        }

        h2 {
            color: lighten(color($medium_key), 20%);
            em { font-weight: $weight-light; color: $gray30; font-size: 28px; font-style: normal; }
        }

        // Color for widget type Detail/Comparison/Heatmap
        h2 .widget-type-label {
            color: #000000;
        }

        h2 .periods {
            display: inline-block;
            white-space: nowrap;
        }

        h2 .period {
            font-weight: $weight-bold;
            font-size: 24px;

            &:before { content: "\2f"; margin-right: .25em; font-weight: $weight-light; color: $gray30; }
            &:after { content: "\2f"; margin-left: .25em; font-weight: $weight-light; color: $gray30; }

            &.actual { color: color($medium_key); }
            &.previous { color: color-previous($medium_key); }

            .day-of-week { font-weight: $weight-light; }
        }

        .actual-part,
        .previous-part {
            @include media(xs) {
                padding-left: 0;
                padding-right: 0;
            }
        }

        .actual-part .primary,
        .actual-part .ternary, { color: color($medium_key); }
        .previous-part .primary,
        .previous-part .ternary { color: color-previous($medium_key); }
        .chart .chart-layer g.step path.color-actual { stroke: color($medium_key); }
        .chart .chart-layer g.bar rect.color-previous { fill: lighten(color-previous($medium_key), 20%); }

        .chart .chart-layer g.step rect.outage.color-previous { stroke: lighten(color-previous($medium_key), 20%); }
        .chart #hatch_previous { stroke: lighten(color-previous($medium_key), 20%); }
        .chart .axis-layer .x.axis.group-actual text {stroke: color($medium_key);}
        .chart .axis-layer .x.axis.group-previous text {stroke: color-previous($medium_key);}

    }


}

    .ratio {
        @include media(xs) { text-align: center; }
        @include media(sm) { text-align: center; }
        @include media(md) { text-align: center; }
        @include media(lg) { text-align: center; }

        #ratio {
            padding-top: 3em;
            max-width: 280px;
        }
    }

    .previous {
        @include media(xs) { text-align: left; }
        @include media(sm) { text-align: right; }
        @include media(md) { text-align: right; }
        @include media(lg) { text-align: right; }

    }

    .icon-arrow-down-right:before {
        color: $brand-success;
        padding-left: 1em;
    }

    .icon-arrow-up-right:before {
        color: $brand-danger;
        padding-left: 1em;
    }

    /*
        SVG chart styles for the widget
    */

    .chart {
        .chart-layer {
            .step.group-previous rect.color-previous {
                opacity: 1
            }
        }
    }

}
