table,
.table {

    & > thead {
        & > tr {
            // border-top: 1px solid $table-border-color;
            border-bottom: 1px solid #000000;
            background-color: #FFFFFF;
        }
        & > tr > th {
            padding: 10px 5px;
            border: none;
            background-color: $table-bg;
            color: $text-color;
            font-weight: normal;
            font-size: 14px;
        }
    }

    & > tbody {
        & > tr {
            border-top: 1px solid $table-border-color;
            border-bottom: 1px solid $table-border-color;

            @each $color_index, $color_value in $streams {
                &.color-#{$color_index} { color: $color_value; }
                &.color-#{$color_index}.group-previous td:first-of-type { color: transparentize($color_value, .5); }
            }
        }
        & > tr > td {
            padding: 10px 5px;
            border: none;
        }
        & > tr > td:first-of-type {
            font-weight: $weight-bold;
        }
    }

    th.text-right,
    td.text-right {
        max-width: 100px;
    }

    &.table-hover > tbody > tr:hover > td,
    &.table-hover > tbody > tr:hover > th {
        background-color: #FFFFFF;
    }
}

.align-decimals td span::not(.day-of-week) {
    display: inline-block;
    width: 100%;
    position: relative;
    text-align: right;
    // color: #000000;
    // font-weight: $weight-bold;
}

.align-decimals td span em {
    display: inline-block;
    position: absolute;
    text-align: left;
    white-space: nowrap;
    font-style: normal;
    line-height: inherit;
}

span.formated {
    opacity: 1;
    font-weight: $weight-bold;
    color: #000000;

    i {
        font-style: normal;
        font-weight: $weight-regular;
        margin-left: 0.25em;
        color: #87888a;
    }
}

// i.unit {
//     font-style: normal;
//     color: #000000;
//     font-weight: $weight-light;
// }
