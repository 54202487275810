.widget-dashboard-command {

    &.context-plugsense .widget-header-icon {
        color: #989898;

        &:before {
            content: medium('plugsense');
        }
    }

    h2 {
        color: color('plugsense');
        em { font-weight: $weight-light; color: $gray30; font-size: 24px; font-style: normal; }
    }

    h2 .widget-type-label {
        color: #000000;
    }

    h2 .period {
        font-weight: $weight-bold;
        font-size: 24px;

        &:before { content: "\2f"; margin-right: .25em; font-weight: $weight-light; color: $gray30; }
        &:after { content: "\2f"; margin-left: .25em; font-weight: $weight-light; color: $gray30; }

    }

    $line-height: 1.1;
    $lines-to-show: 2;
    $label-font-size: 18px;

    .stream-block {
        padding: 0 20px 0 52px;
        margin-bottom: 20px;

        @include media(xs) {
            padding: 1.5em .5em 0 .1em;
        }

        line-height: $line-height;

        &.health-good {
            color: #000000;
            background-color: #FFFFFF;
            border: 1px solid $gray30;
        }
        &.health-bad {
            color: $gray20;
            background-color: transparent;
            border: 1px solid $gray20;
        }
        &.health-unknown {
            color: #000000;
            background-color: #FFFFFF;
            border: 1px solid $gray30;
        }
    }

    .streams {
        .row > div {
            text-align: right;
        }

        /* A color dot next to the stream value signalling sensor status */
        i {
            font-style: normal;
            font-size: $font-size-small;
            display: block;

            &:before {
                position: absolute;
                top: $padding-base-vertical;
                right: ($padding-base-horizontal * 1.5);
            }

            /* Success - means there was a new value from the sensor within ROTTEN limit */
            &.health-good {
                color: transparent;
            }

            /* Error - means there was no new value from the sensor within ROTTEN limit */
            &.health-bad {
                color: #f70017;
            }

            /* Unknown - we don't know */
            &.health-unknown {
                color: transparent;
            }
        }

        /* Stream label */
        p {
            color: $gray30;
            font-size: $label-font-size;
            font-weight: $weight-light;
            text-align: right;
            display: block; /* Fallback for non-webkit */
            display: -webkit-box;
            height: ($label-font-size*$line-height*$lines-to-show); /* Fallback for non-webkit */
            -webkit-line-clamp: $lines-to-show;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .stream {
        font-weight: $weight-regular;
        font-size: 62px;
        letter-spacing: -0.04em;

        -webkit-animation: fadeIn 2s; /* Safari 4+ */
        -moz-animation:    fadeIn 2s; /* Fx 5+ */
        -o-animation:      fadeIn 2s; /* Opera 12+ */
        animation:         fadeIn 2s; /* IE 10+ */

        b {
            font-weight: $weight-light;
            font-size: 32px;
            margin-left: 6px;
            display: inline-block;
        }

        a:hover {
            text-decoration: none;
        }
    }

@each $medium_key, $medium_value in $medium {

    .context-#{$medium_key} {
        &.health-good.stream-block:hover {
            border-color: color($medium_key);
            p { color: #000000; }
        }
        &.health-bad.stream-block:hover {
            border-color: $gray20;
            background-color: $gray10;
            p { color: #000000; }
        }

        &.health-bad .value {
            visibility: visible;
            display: inline-block;
            white-space: nowrap;
        }


        &.health-bad:hover .value {
            visibility: hidden;
            display: none;
        }

        &.health-bad .health-description {
            visibility: hidden;
            display: none;
        }

        &.health-bad:hover .health-description {
            color: $brand-danger;
            font-size: 16px;
            font-weight: $weight-light;
            white-space: normal;
            letter-spacing: normal;
            visibility: visible;
            display: inline-block;
        }

        .stream:before {
            @include icon-emIconFont();
            top: (62px - 42px);
            left: 20px;
            position: absolute;
            font-size: 42px;
            content: medium($medium_key);

            @include media(xs) {
                top: 5px;
                left: 15px;
                font-size: 32px;
            }
        }

        &.health-good .stream:before {
            color: color($medium_key);
        }
        &.health-bad .stream:before {
            color: $gray20;
        }
    }

}


    svg {
        #switchBackground {
            fill: #c4c6c7;
        }

        #emblem path {
            stroke: #c4c6c7;
            fill: none;
        }

        #switchLabel {
            fill: #FFFFFF;
            font-weight: 700;
            font-size: 38px;
            text-anchor: middle;
        }
    }

    .connection-down,
    .connection-unknown {
        cursor: default;

        svg {
            #switchBackground {
                fill: #c4c6c7;
            }

            #emblem path {
                stroke: #c4c6c7;
                fill: none;
            }
        }
    }

    .connection-up.switch-is-unknown {

        svg {
            #switchBackground {
                fill: #c4c6c7;
            }

            #emblem path {
                stroke: #c4c6c7;
                fill: none;
            }
        }

    }

    .connection-up.switch-is-unknown.transitioning-from-on {

        svg {
            #switchBackground {
                fill: #efbdc2;
            }
        }

    }

    .connection-up.switch-is-unknown.transitioning-from-off {

        svg {
            #switchBackground {
                fill: #c5debe;
            }
        }

    }

    .connection-up.switch-is-off {

        svg {
            #switchBackground {
                fill: #fb001c;
            }

            #emblem path {
                stroke: #fb001c;
                fill: none;
            }
        }

    }

    .connection-up.switch-is-on {

        svg {
            #switchBackground {
                fill: #3cb419;
            }

            #emblem path {
                stroke: #3cb419;
                fill: none;
            }
        }

    }

    .connection-up .command-button {
        cursor: pointer;
    }

    .command-button {
        display: block;
        margin: 20px auto;
    }

    .command-label {
        font-size: 22px;
        line-height: normal;
        min-height: 60px;
    }

    .command-mode {
        font-size: 12px;
        display: inline-block;
    }

    .command-button svg #switchButton {
        transition: transform .1s cubic-bezier(0.1, 0.8, 0.9, 1.0);
    }

    .command-button svg #switchLabel {
        transition: all .1s cubic-bezier(0.1, 0.8, 0.9, 1.0);
    }

    .command-button svg #switchBackground {
        transition: fill .1s ease-in;
    }

    .command-button svg {
        max-width: 50%;
        max-height: 75px;

        @include media(xs) {
            max-width: 75%;
            max-height: 40px;
        }
    }

    .alert-inline {
      background-color: transparent;
      border-color: transparent;
      padding: $alert-padding 0;
    }

    div[data-region=reason] .alert {
      -webkit-animation: slideDown .25s; /* Safari 4+ */
      -moz-animation:    slideDown .25s; /* Fx 5+ */
      -o-animation:      slideDown .25s; /* Opera 12+ */
      animation:         slideDown .25s; /* IE 10+ */
    }
}
