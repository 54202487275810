/*!
 * Datepicker for Bootstrap
 *
 * Copyright 2012 Stefan Petre
 * Improvements by Andrew Rowls
 * SASS conversion by Lukas Stancik
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 */

$white: #ffffff;
$grayLighter: #eeeeee;

.datepicker {

    z-index: 9000 !important;

	padding: 4px;
	@include border-radius($border-radius-small);
	.datepicker-inline {
		width: 220px;
	}
	direction: ltr;
	.datepicker-rtl {
		direction: rtl;
		table tr td span {
			float: right;
		}
	}
	.datepicker-dropdown {
		top: 0;
		left: 0;
		&:before {
			content: '';
			display: inline-block;
			border-left:   7px solid transparent;
			border-right:  7px solid transparent;
			border-bottom: 7px solid #ccc;
			border-top:    0;
			border-bottom-color: rgba(0,0,0,.2);
			position: absolute;
		}
		&:after {
			content: '';
			display: inline-block;
			border-left:   6px solid transparent;
			border-right:  6px solid transparent;
			border-bottom: 6px solid $white;
			border-top:    0;
			position: absolute;
		}
		&.datepicker-orient-left:before   { left: 6px; }
		&.datepicker-orient-left:after    { left: 7px; }
		&.datepicker-orient-right:before  { right: 6px; }
		&.datepicker-orient-right:after   { right: 7px; }
		&.datepicker-orient-top:before    { top: -7px; }
		&.datepicker-orient-top:after     { top: -6px; }
		&.datepicker-orient-bottom:before {
			bottom: -7px;
			border-bottom: 0;
			border-top:    7px solid #999;
		}
		&.datepicker-orient-bottom:after {
			bottom: -6px;
			border-bottom: 0;
			border-top:    6px solid $white;
		}
	}
	& > div {
		display: none;
	}
	&.days div.datepicker-days {
		display: block;
	}
	&.months div.datepicker-months {
		display: block;
	}
	&.years div.datepicker-years {
		display: block;
	}
	table > tbody > tr,
	table > thead > tr {
		border: none;
	}
	table > thead > tr > th {
		background-color: transparent;
	}
	table > thead > tr:last-of-type {
		border-bottom: 1px solid $dropdown-divider-bg;
	}
	table{
		margin: 0;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
	td,
	th{
		text-align: center;
		width: 20px;
		height: 20px;
		@include border-radius($border-radius-small);

		border: none;
	}
    /*
    Inline display inside a table presents some problems with
	border and background colors.
    */
    .table-striped .datepicker table tr {
		td, th {
			background-color:transparent;
		}
	}
	table tr td {
		&.day,
		&.month,
		&.year {
			background: $dropdown-bg;
			color: $dropdown-link-color;
			cursor: pointer;

			&:hover {
				color: $dropdown-link-hover-color;
				background-color: $dropdown-link-hover-bg;
			}
		}

		&.old,
		&.old.disabled
		&.old.disabled:hover,
		&.new,
		&.new.disabled,
		&.new.disabled:hover {
			color: transparentize($dropdown-link-color, .75);
		}

		&.disabled,
		&.disabled:hover {
			background: none;
			color: transparentize($dropdown-link-color, .35);
			cursor: not-allowed;
		}

		&.active {
			background-color: $dropdown-link-active-bg;
			color: $dropdown-link-active-color;
		}

		/*
		&.today,
		&.today:hover,
		&.today.disabled,
		&.today.disabled:hover {
			$todayBackground: lighten($orange, 30%);
			@include buttonBackground($todayBackground, adjust-hue($todayBackground, 20));
			color: #000;
		}
		&.today:hover:hover {
			color: #000;
		}
		&.today.active:hover {
			color: #fff;
		}


		&.range,
		&.range:hover,
		&.range.disabled,
		&.range.disabled:hover {
			background:$grayLighter;
			@include border-radius($border-radius-small);
		}
		&.range.today,
		&.range.today:hover,
		&.range.today.disabled,
		&.range.today.disabled:hover {
			$todayBackground: mix($orange, $grayLighter, 50%);
			@include buttonBackground($todayBackground, adjust-hue($todayBackground, 20));
			@include border-radius($border-radius-small);
		}
		&.selected,
		&.selected:hover,
		&.selected.disabled,
		&.selected.disabled:hover {
			background-color: transparent;
			color: $dropdown-link-disabled-color;
		}
		*/

		span {
			display: block;
			width: 23%;
			height: 54px;
			line-height: 54px;
			float: left;
			margin: 1%;
			cursor: pointer;
			background: $dropdown-bg;
			color: $dropdown-link-color;
			@include border-radius($border-radius-small);
			&:hover {
				background-color: $dropdown-link-hover-bg;
				color: $dropdown-link-color;
			}
			&.disabled,
			&.disabled:hover {
				background:none;
				color: transparentize($dropdown-link-color, .35);
				cursor: not-allowed;
			}
			&.active {
				background-color: $dropdown-link-active-bg;
				color: $dropdown-link-active-color;
			}
			&.old,
			&.old.disabled
			&.old.disabled:hover,
			&.new,
			&.new.disabled,
			&.new.disabled:hover {
				color: transparentize($dropdown-link-color, .75);
			}
		}
	}

	th.datepicker-switch {
		width: 145px;
	}

	thead tr:first-child th,
	tfoot tr th {
		cursor: pointer;
		&:hover{
			color: $dropdown-link-hover-color;
			background-color: $dropdown-link-hover-bg;
		}
	}
	.dow {
		background-color: transparent;
	}

    /*Basic styling for calendar-week cells*/
	.cw {
		font-size: 10px;
		width: 12px;
		padding: 0 2px 0 5px;
		vertical-align: middle;
	}
	thead tr:first-child th.cw {
		cursor: default;
		background-color: transparent;
	}
}
.input-append,
.input-prepend {
	&.date {
		.add-on i {
			display: block;
			cursor: pointer;
			width: 16px;
			height: 16px;
		}
	}
}
.input-group-addon {
	color: $link-color;
	cursor: pointer;
}

.input-daterange {
	input {
		text-align:center;
	}
	input:first-child {
		@include border-radius($border-radius-small 0 0 $border-radius-small);
	}
	input:last-child {
		@include border-radius(0 $border-radius-small $border-radius-small 0);
	}
	.add-on {
		display: inline-block;
		width: auto;
		min-width: 16px;
		height: $line-height-base;
		padding: 4px 5px;
		font-weight: normal;
		line-height: $line-height-base;
		text-align: center;
		text-shadow: 0 1px 0 $white;
		vertical-align: middle;
		background-color: $grayLighter;
		border: 1px solid #ccc;
		margin-left:-5px;
		margin-right:-5px;
	}
}
