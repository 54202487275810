legend {
    border: none;
}

label {
    font-weight: $weight-light;
}

.required {
    font-weight: $weight-bold;
    color: #000000;
    position: relative;


    &:after {
        content: "*";
        position: absolute;
        color: $brand-primary;
        font-weight: normal;
    }
}

.has-error {
    .control-label,
    .required,
    &.required {
        color: #000000;
        font-weight: $weight-bold;

        &:after {
            color: $brand-primary;
        }
    }
}
