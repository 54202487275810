.settings {

    table {

        input[type="text"],
        select {
            @extend .form-control;
        }
        tr.disabled {
            input[disabled],
            select[disabled] {
                background-color: $input-bg-disabled;
                color: $btn-link-disabled-color;
            }
            .form-control-static{
                color: $btn-link-disabled-color;
            }
            button {
                background-color: $input-bg-disabled;
                color: $btn-link-disabled-color;
            }
        }
    }

    tbody.sort td {
        vertical-align: middle;
    }

    .btn.password-reset {
        font-weight: $weight-regular;

        &:hover,
        &:focus {
            color: $link-hover-color;
        }
    }
}

.settings.notifications {
    fieldset {
        margin-top: 2em;

        legend {
            font-size: $font-size-large;
            font-weight: $weight-light;
        }
    }
}

.icon-question-sign[data-togle=modal] {
    cursor: pointer;
}

.settings,
.ui-sortable-helper {
    .btn-info.scheme {
        @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);
        &:hover{
            background-color: $btn-default-bg;
            border-color: $btn-default-border;
            color: $link-hover-color;
        }
        &:focus {
            background-color: $btn-default-bg;
            border-color: $btn-default-border;
        }
    }
    .ordering a {
        text-decoration: none;
    }
    .remove-widget,
    .remove-token,
    .remove-trigger,
    .edit-trigger,
    .sort-widget {
        border: none;
        background-color: transparent;

        &:hover,
        &:focus,
        &:active,
        &.active {
            background-color: transparent;
            @include box-shadow(none);
        }
    }
    .remove-widget,
    .remove-token,
    .remove-trigger {
        color: $brand-danger;
    }
}

/*
    Styling for interactive chart used for interactive setting of switching scheme
*/

#switching_place {
    .level-0  { select, input { border-color: transparentize(map-get($streams,"0-0"),.5); color: map-get($streams,"0-0"); } rect.slider-body { fill: map-get($streams,"0-0"); } }
    .level-1  { select, input { border-color: transparentize(map-get($streams,"0-2"),.5); color: map-get($streams,"0-2"); } rect.slider-body { fill: map-get($streams,"0-2"); } }
    .level-2  { select, input { border-color: transparentize(map-get($streams,"1-0"),.5); color: map-get($streams,"1-0"); } rect.slider-body { fill: map-get($streams,"1-0"); } }

    .slider-start {
        fill: $gray-lighter;
        cursor: w-resize;

    }
    .slider-end {
        fill: $gray-lighter;
        cursor: e-resize;
    }
    .slider-body {
        cursor: move;
    }

    .slider-start,
    .slider-end,
    .slider-body {
        &.disabled {
            cursor: default;
        }
    }

    .chart { margin: 2em 2em 2em 0; }
    .table-switching-inputs input { width: 5.5em; }
    .table-switching-inputs input.rate-label { width: 30em; }
}

.color-settings {
    button {
        font-weight: $weight-regular;
        @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);
        width: 100%;
        min-height: $line-height-computed + ($padding-base-vertical * 2);

        &:hover{
            background-color: $btn-default-bg;
            border-color: $btn-default-border;
            color: $link-hover-color;
        }
        &:focus {
            background-color: $btn-default-bg;
            border-color: $btn-default-border;
        }
    }

    @each $color_index, $color_value in $streams {
        .color-#{$color_index} {
            background-color: $color_value;
            border-color: darken($color_value, 15%);
            color: black;

            &:hover {
                background-color: darken($color_value, 10%);
                color: black;
            }

            &:active,
            &:focus {
                background-color: darken($color_value, 20%);
            }
        }
    }
}

// Inside the modal window with color selection
#colorselector {
    button {
        margin: .5em;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.inline-spinner {
    background: url(../spinner18_#{$theme}.gif);
    display: inline-block;
    min-width: 18px;
    min-height: 18px;
}

.sort-cancel {
    -webkit-animation: flashError 1s 1 forwards;
    -moz-animation:    flashError 1s 1 forwards;
    -o-animation:      flashError 1s 1 forwards;
    animation:         flashError 1s 1 forwards;
}

.sort-success {
    -webkit-animation: flashSuccess 1s 1 forwards;
    -moz-animation:    flashSuccess 1s 1 forwards;
    -o-animation:      flashSuccess 1s 1 forwards;
    animation:         flashSuccess 1s 1 forwards;
}

.item-removed {
  -webkit-animation: itemRemoved .8s cubic-bezier(.65,-0.02,.72,.29);
  -moz-animation: itemRemoved .8s cubic-bezier(.65,-0.02,.72,.29);
  -o-animation: itemRemoved .8s cubic-bezier(.65,-0.02,.72,.29);
  animation: itemRemoved .8s cubic-bezier(.65,-0.02,.72,.29)
}

.item-refused {
  -webkit-animation: itemRefused .4s;
  -moz-animation: itemRefused .4s;
  -o-animation: itemRefused .4s;
  animation: itemRefused .4s
}

.ordering .icon-move {
    cursor: pointer;
}

.center-text {
    text-align: center;
}

#switching_place_note_6 {
  padding: 2em 0 0 0;
}

.gdpr {
  .row {
      margin-bottom: 40px;
  }
  a {
    color: #e35205;
  }
}
