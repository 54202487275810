.widget-detail-heatmap {

    @each $medium_key, $medium_value in $medium {

        &.context-#{$medium_key} {


            .widget-header-icon {
                color: color($medium_key);

                &:before {
                    @include unicode_content(medium($medium_key));
                }
            }

            h2 {
                color: lighten(color($medium_key), 20%);
                em { font-weight: $weight-light; color: $gray30; font-size: 24px; font-style: normal; }
            }

            // Color for widget type Detail/Comparison/Heatmap
            h2 .widget-type-label {
                color: #000000;
            }

            h2 .period {
                font-weight: $weight-bold;
                font-size: 24px;

                &:before { content: "\2f"; margin-right: .25em; font-weight: $weight-light; color: $gray30; }
                &:after { content: "\2f"; margin-left: .25em; font-weight: $weight-light; color: $gray30; }

                &.actual { color: color($medium_key); }
                &.previous { color: color-previous($medium_key); }

                .day-of-week { font-weight: $weight-light; }
            }

            /*
                SVG chart styles for the widget
            */

            .chart {
                .axis-layer {
                    .dayLabel.weekEnd,
                    text.hourLabel.workHour {
                        font-weight: bold;
                    }
                    text.daySum {
                        fill: #000000;
                    }
                }
                .chart-layer {
                    text.check {
                        fill: #000000;
                        fill-opacity: 0;
                    }
                    text.check.check_visible {
                        fill-opacity: .5;
                    }

                    g.day:hover text.check {
                        fill: #000000;
                        fill-opacity: 1;
                    }

                    rect.bordered.daySumBg {
                        fill-opacity: .5;
                        fill: color($medium_key);
                    }
                    rect.bordered.daySumBg.tempPlus {
                        fill-opacity: .5;
                        fill: #D09060;
                    }
                    rect.bordered.daySumBg.tempMinus {
                        fill-opacity: .5;
                        fill: #60B0D0;
                    }
                    rect.bordered.light {
                        stroke: $btn-default-bg;
                        shape-rendering: crispEdges;
                    }
                }
                rect.bordered,
                rect.bordered {
                    stroke: $body-bg;
                    stroke-width: 1px;
                }
            }


        }
    }
}
