.widget-content-block {
    border: 1px solid $gray30;
}

.spinner {
    display: block;
    background-color: transparent;
    text-align: center;
    font-size: 16px;
    width: 33%;
    min-height: 64px;
    margin: 0 auto;
    padding: 1em;

    &.on {
    }

    &.on:before {
        @include icon-emIconFont();
        content: $emicon-x2;
        display: inline-block;
        font-size: 46px;
        width: 46px;
        height: 64px;
        -moz-animation: spin 2s infinite linear;
        -o-animation: spin 2s infinite linear;
        -webkit-animation: spin 2s infinite linear;
        animation: spin 2s infinite linear;
    }
}

.widget {
    margin-top: 20px;

    h2 {
        margin: 0;
        font-size: 32px;

        &.text-success { color: $brand-success; }
        &.text-info { color: $brand-info; }

        & > .text-success { color: $brand-success; }
        & > .text-info { color: $brand-info; }

        .widget-type-label {
            margin: 0 24px;
        }
    }

    .blank {
        width: 33%;
        padding: 1em;
        text-align: center;
        margin: 0 auto;
    }

    // .context-dashboard .widget-header-icon:before { @include unicode_content($map-marker); color: color('dashboard'); }
    // .context-electricity .widget-header-icon:before { @include unicode_content($bolt); color: color('electricity'); }
    // .context-gas .widget-header-icon:before { @include unicode_content($fire); color: color('gas'); }
    // .context-water .widget-header-icon:before { @include unicode_content($tint); color: color('water'); }
    // .context-temperature .widget-header-icon:before { @include unicode_content($dashboard); color: color('temperature'); }
    // .context-humidity .widget-header-icon:before { @include unicode_content($tint); color: color('humidity'); }
    // .context-plugsense .widget-header-icon:before { @include unicode_content($gift); color: color('plugsense'); }
    // .context-display .widget-header-icon:before { @include unicode_content($leaf); color: color('display'); }

    .widget-header-icon {
        @include icon-emIconFont();
        font-size: 46px;
        display: inline-block;
        position: absolute;
        left: -23px;
        top: -8px;

        @include media(xs) {
            font-size: 32px;
            left: 0;
            top: 0;
        }
    }

    .widget-header {
        margin-bottom: 20px;
    }

    .snap-to-chart {
        @include media(lg) {
            padding-top: 0;
            padding-right: ($chart-internal-square*6)+$padding-small-horizontal;
            padding-bottom: 0;
            padding-left: ($chart-internal-square*6)+$padding-small-horizontal;
        }
    }

    .table {
        td {
            white-space: nowrap;
        }
    }

    .is-working {
        position: relative;
        opacity: .6;
        .widget-header-icon:after {
            content: $emicon-y;
            position: absolute;
            background-color: transparent;
            display: block;
            z-index: 10;
            top: 0;
            color: $body-bg;
            opacity: 1;
            -moz-animation: spin 2s infinite linear;
            -o-animation: spin 2s infinite linear;
            -webkit-animation: spin 2s infinite linear;
            animation: spin 2s infinite linear;
        }
    }
}

@import 'widget/dashboard-live';
@import 'widget/dashboard-command';
@import 'widget/dashboard-heatmap';
@import 'widget/dashboard-comparison';
@import 'widget/panel';
@import 'widget/detail';
@import 'widget/detail-comparison';
@import 'widget/detail-heatmap';
