.widget-dashboard-live {

    &.context-dashboard .widget-header-icon {
        color: #989898;

        &:before {
            content: medium('dashboard');
        }
    }

    h2 {
        color: color('dashboard');
        em { font-weight: $weight-light; color: $gray30; font-size: 24px; font-style: normal; }
    }

    h2 .widget-type-label {
        color: #000000;
    }

    h2 .period {
        font-weight: $weight-bold;
        font-size: 24px;

        &:before { content: "\2f"; margin-right: .25em; font-weight: $weight-light; color: $gray30; }
        &:after { content: "\2f"; margin-left: .25em; font-weight: $weight-light; color: $gray30; }

        &.actual { color: color('dashboard'); }
        &.previous { color: lighten(color('dashboard'), 20%); }

        .day-of-week { font-weight: $weight-light; }
    }

    $line-height: 1.1;
    $lines-to-show: 2;
    $label-font-size: 14px;

    .stream-block {
        padding: 0 20px 0 52px;
        margin-bottom: 20px;

        @include media(xs) {
            padding: 1.5em .5em 0 .1em;
        }

        line-height: $line-height;

        &.health-good {
            color: #000000;
            background-color: #FFFFFF;
            border: 1px solid $gray30;
        }
        &.health-bad {
            color: $gray20;
            background-color: transparent;
            border: 1px solid $gray20;
        }
        &.health-unknown {
            color: #000000;
            background-color: #FFFFFF;
            border: 1px solid $gray30;
        }
    }

    .streams {
        .row > div {
            text-align: right;
        }

        /* A color dot next to the stream value signalling sensor status */
        i {
            font-style: normal;
            font-size: $font-size-small;
            display: block;

            &:before {
                position: absolute;
                top: $padding-base-vertical;
                right: ($padding-base-horizontal * 1.5);
            }

            /* Success - means there was a new value from the sensor within ROTTEN limit */
            &.health-good {
                color: transparent;
            }

            /* Error - means there was no new value from the sensor within ROTTEN limit */
            &.health-bad {
                color: #f70017;
            }

            /* Unknown - we don't know */
            &.health-unknown {
                color: transparent;
            }
        }

        /* Stream label */
        p {
            color: $gray30;
            font-size: $label-font-size;
            font-weight: $weight-light;
            text-align: right;
            display: block; /* Fallback for non-webkit */
            display: -webkit-box;
            height: ($label-font-size*$line-height*$lines-to-show); /* Fallback for non-webkit */
            -webkit-line-clamp: $lines-to-show;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .stream {
        font-weight: $weight-regular;
        font-size: 62px;
        letter-spacing: -0.04em;

        -webkit-animation: fadeIn 2s; /* Safari 4+ */
        -moz-animation:    fadeIn 2s; /* Fx 5+ */
        -o-animation:      fadeIn 2s; /* Opera 12+ */
        animation:         fadeIn 2s; /* IE 10+ */

        b {
            font-weight: $weight-light;
            font-size: 32px;
            margin-left: 6px;
            display: inline-block;
        }

        a:hover {
            text-decoration: none;
        }
    }

@each $medium_key, $medium_value in $medium {

    .context-#{$medium_key} {
        &.health-good.stream-block:hover {
            border-color: color($medium_key);
            p { color: #000000; }
        }
        &.health-bad.stream-block:hover {
            border-color: $gray20;
            background-color: $gray10;
            p { color: #000000; }
        }

        &.health-bad .value {
            visibility: visible;
            display: inline-block;
            white-space: nowrap;
        }


        &.health-bad:hover .value {
            visibility: hidden;
            display: none;
        }

        &.health-bad .health-description {
            visibility: hidden;
            display: none;
        }

        &.health-bad:hover .health-description {
            color: $brand-danger;
            font-size: 16px;
            font-weight: $weight-light;
            white-space: normal;
            letter-spacing: normal;
            visibility: visible;
            display: inline-block;
        }

        .stream:before {
            @include icon-emIconFont();
            top: (62px - 42px);
            left: 20px;
            position: absolute;
            font-size: 42px;
            content: medium($medium_key);

            @include media(xs) {
                top: 5px;
                left: 15px;
                font-size: 32px;
            }
        }

        &.health-good .stream:before {
            color: color($medium_key);
        }
        &.health-bad .stream:before {
            color: $gray20;
        }
    }

}

} // .widget-dashboard-live
