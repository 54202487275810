/*
    HACK worth of a comment

    Y-axis (typically positioned at 0 and bottom edge of chart) is source of problems. As soon as it
    starts to display negative values, there is a path drawn overlapping 0 and it sometimes covers chart data
    Tried to tame d3.axis for a while but the easiest way is to solve it here in CSS. It can be hidden, no harm in that.
*/

.chart {
    margin-bottom: $padding-large-vertical * 2;
}

.axis-layer .x.axis path.domain {
    display:none;
}

.chart text {
    font-size: 12px;
    font-weight: normal;
}


/*
    Styling of axis parts of charts
*/
.chart .axis-layer line,
.chart .axis-layer path {
    fill: none;
    stroke: map-get($base-chart,axis-stroke-color);
    shape-rendering: crispEdges;
}
.chart .axis-layer text {
    fill: map-get($base-chart,axis-text-color);
    shape-rendering: crispEdges;
}

/*
    Grid inside the charts helping to grasp the x- and y- domains
*/

.chart .grid-layer {
    fill: #FFFFFF;
}

.chart .grid-layer line {
    fill: none;
    stroke: map-get($base-chart,axis-stroke-color);
    shape-rendering: geometricPrecision;
    stroke-dasharray: 2,2;
    opacity: map-get($base-chart,axis-opacity);
}
.chart .grid-layer path {
    fill: none;
    stroke: none;
}

.chart .grid_background-layer {
    fill: #FFFFFF;
}


/*
* Continuous charts
*/

.chart .chart-layer path.group-actual {
    stroke-width: 1.2px;
    fill: none;
}

.chart .chart-layer {

    @each $color_index, $color_value in $streams {
        /* Detailed continuous charts */
        path.group-actual.color-#{$color_index} {stroke: $color_value;}

        /* Permanent consumption chart peaks */
        circle.group-actual.color-#{$color_index} {fill: $color_value;}

        /* Detailed bar charts */
        g.bar.color-#{$color_index} rect {fill: $color_value;}

        /* Continuous comparison charts */
        g.continuous.group-actual path.color-#{$color_index} {stroke: $color_value;}
        g.continuous.group-previous path.color-#{$color_index} {fill: $color_value;}

        /* Step and bar charts */
        g.step path.color-#{$color_index} {stroke: $color_value;}
        g.step rect.color-#{$color_index} {fill: $color_value;}
        g.bar path.color-#{$color_index} {stroke: $color_value;}
        g.bar rect.color-#{$color_index} {fill: $color_value;}
    }

    path.dim,
    circle.dim,
    g.dim rect,
    g.dim path {
        opacity: .1;
    }

    /*
        The comment on the very end is needed for grunt task that compiles
        image data using base64 encoding and puts it inside CSS file
    */

    @each $color_index, $hatch_index in $hatches {
        /* Continuous comparison charts */
        g.bar.color-#{$color_index} rect.outage {fill: url('#hatch_#{$hatch_index}'); /*ImageEmbed:skip*/}
        /* Step and bar charts */
        g.step rect.outage.color-#{$color_index} {fill: url('#hatch_#{$hatch_index}'); /*ImageEmbed:skip*/}

    }

    /*
        Continuous comparison charts
    */

    g.continuous.group-actual path { stroke-width: 1.2px; fill: none; }
    g.dim.continuous.group-actual path { opacity: 0.05; }
    g.continuous.group-previous path { stroke: none; opacity: .2; }
    g.dim.continuous.group-previous path { opacity: 0.04; }

    /*
        Step and bar charts
    */

    g.step path { stroke-width: 2.8px; fill: transparent; }
    g.dim.step path { opacity: 0.05; }
    g.step rect { opacity: .2; }
    g.step.dim rect { opacity: 0.05; }
    g.step rect.color-previous {fill-opacity: 1;}
    g.bar.group-previous rect {fill-opacity: 1;}

}

.widget-electricity-comparison .chart .chart-layer g.bar.group-previous rect {fill-opacity: .4;}
.widget-water-comparison .chart .chart-layer g.bar.group-previous rect {fill-opacity: .4;}
.widget-gas-comparison .chart .chart-layer g.bar.group-previous rect {fill-opacity: .4;}
.widget-temperature-comparison .chart .chart-layer g.bar.group-previous rect {fill-opacity: .4;}


/*
    Patterns and hatches for detailed bar charts
*/

.hatch {stroke-width: 2.1;}

@each $color_index, $hatch_index in $hatches {
    @include hatch(#{$hatch_index},#{$color_index},$streams);
}

#hatch_dim {stroke: map-get($base-chart,axis-stroke-color);}



/*
    Colors for x-axis labels on dashboard
*/

.dashboard .widget .chart .axis-layer .x.axis.group-actual text {stroke: $color_actual;}
.dashboard .widget .chart .axis-layer .x.axis.group-previous text {stroke: $color-previous;}


/*
    Colors for x-axis labels in comparison charts (currently only electricity)
*/


.electricity .widget .chart .axis-layer .x.axis {

    @each $color_index, $color_value in $streams {
        &.color-#{$color_index} {stroke: $color_value;}
    }

    &.group-previous {opacity: .5;}
}


/*
    Zebra stripes for weeks in charts
*/

.background-layer rect.week-0 {opacity:0;}
.background-layer rect.week-1 {fill: map-get($base-chart, odd-week-fill); opacity: map-get($base-chart, odd-week-opacity);}

/*
    Switching scheme specific styles
*/

.background-layer rect.switching-None,
.background-layer rect.switching-null,
.background-layer rect.switching-0 {opacity:0;}
.background-layer rect.switching-1 {fill:$switching-bg; opacity:.05;}
.background-layer rect.switching-2 {fill:$switching-bg; opacity:.1;}


/*
    Styles for a cursor showing current x-axis position when hovering over chart
*/

.highlight-layer rect.cursor {
    opacity:0;
    fill: map-get($base-chart, cursor-bg);
}
.overlay-layer g.label rect {
    fill: $body-bg;
}
.overlay-layer g.label text {
    font-size: 12px;
    font-weight: normal;
    text-anchor: middle;
    fill: $text-color;
    shape-rendering: crispEdges;
}
.overlay-layer g.label line {
    fill: none;
    stroke: $text-color;
    shape-rendering: crispEdges;
    stroke-width: 1;
}

/*
    Styles for a tooltip when hovering over chart (we call it a bubble)
*/

.overlay-layer g.bubble rect {
    fill: map-get($base-chart, bubble-bg);
    opacity: map-get($base-chart, bubble-opacity);
    stroke: map-get($base-chart, bubble-border);
    stroke-width: 1px;
}

/*
    Colors used for text inside a tooltip (bubble)
    Different for every stream (matching the color of stream data in chart)
*/
.chart .overlay-layer text {
    @each $color_index, $color_value in $streams {
        &.color-#{$color_index} {fill: $color_value;}
    }
}


/*
    Savings center specific styles
*/

.savings {
    .events-layer {
        cursor: pointer;
    }
    .overlay-layer g.bubble text {
        font-size: 13px;
    }

    .chart .overlay-layer .bubble text.color-neutral {fill: map-get($base-chart,axis-text-color);}
    .chart .overlay-layer .bubble .wrapped {
        color: map-get($base-chart,axis-text-color);
        font-size: 13px;
    }
    .chart .overlay-layer .bubble ul,
    .chart .overlay-layer .bubble ul li {
        margin: 0;
    }
    .chart .overlay-layer .bubble ul {
        padding-top: 10px;
        padding-left: 25px;
        list-style-type: disc;
    }


    .chart .chart-layer circle.group-actual.color-0-1 {
        fill: map-get($alwayson, circle-fill);
    }
    .chart .chart-layer path.line {
        stroke: map-get($alwayson, circle-fill);
        stroke-width: 2;
        opacity: .25;
    }
    .chart .chart-layer path.area {
        fill: map-get($alwayson, bg-fill);
        stroke-width: 0;
        opacity: 0.05;
    }
    .donut-chart .color-0-1 {
        fill: lighten($brand-primary, 10%);
    }
}
