@include keyframes("fadeIn"){
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

@include keyframes("fadeInLight"){
  0%   { opacity: .5; }
  100% { opacity: 1; }
}

@include keyframes("fadeOutLight"){
  0%   { opacity: 1; }
  100% { opacity: .5; }
}

@include keyframes("flashError"){
  0%   { background-color: rgba(red($brand-danger),green($brand-danger),blue($brand-danger),.1); }
  100% { background-color: inherit; }
}

@include keyframes("flashSuccess"){
  0%   { background-color: rgba(red($brand-success),green($brand-success),blue($brand-success),.1); }
  100% { background-color: inherit; }
}

@include keyframes("itemRemoved"){
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0)
  }

  30% {
    opacity: 1;
    -webkit-transform: translateX(50px);
    -ms-transform: translateX(50px);
    -o-transform: translateX(50px);
    transform: translateX(50px)
  }

  80% {
    opacity: 1;
    -webkit-transform: translateX(-800px);
    -ms-transform: translateX(-800px);
    -o-transform: translateX(-800px);
    transform: translateX(-800px)
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(-800px);
    -ms-transform: translateX(-800px);
    -o-transform: translateX(-800px);
    transform: translateX(-800px)
  }
}

@include keyframes("itemRefused"){
    $shake: 20px;
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0)
    }

    12% {
        opacity: 1;
        -webkit-transform: translateX($shake);
        -ms-transform: translateX($shake);
        -o-transform: translateX($shake);
        transform: translateX($shake)
    }

    38% {
        opacity: 1;
        -webkit-transform: translateX(-$shake);
        -ms-transform: translateX(-$shake);
        -o-transform: translateX(-$shake);
        transform: translateX(-$shake)
    }

    50% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0)
    }

    62% {
        opacity: 1;
        -webkit-transform: translateX($shake);
        -ms-transform: translateX($shake);
        -o-transform: translateX($shake);
        transform: translateX($shake)
    }

    88% {
        opacity: 1;
        -webkit-transform: translateX(-$shake);
        -ms-transform: translateX(-$shake);
        -o-transform: translateX(-$shake);
        transform: translateX(-$shake)
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0)
    }
}

@include keyframes("fingerpointLeft"){
    $shake: 10px;
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0)
    }

    12% {
        opacity: 1;
        -webkit-transform: translateX($shake);
        -ms-transform: translateX($shake);
        -o-transform: translateX($shake);
        transform: translateX($shake)
    }

    38% {
        opacity: 1;
        -webkit-transform: translateX(-$shake);
        -ms-transform: translateX(-$shake);
        -o-transform: translateX(-$shake);
        transform: translateX(-$shake)
    }

    50% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0)
    }

    62% {
        opacity: 1;
        -webkit-transform: translateX($shake);
        -ms-transform: translateX($shake);
        -o-transform: translateX($shake);
        transform: translateX($shake)
    }

    88% {
        opacity: 1;
        -webkit-transform: translateX(-$shake);
        -ms-transform: translateX(-$shake);
        -o-transform: translateX(-$shake);
        transform: translateX(-$shake)
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0)
    }
}

@include keyframes("slide"){
    0% {
        right: 80%;
        left: 2px;
    }
    5% {
        left: 2px;
    }
    50% {
        right: 2px;
        left: 80%;
    }
    55% {
        right: 2px;
    }
    100% {
        right: 80%;
        left: 2px;
    }
}

@include keyframes("slideDown") {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%)
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0)
  }
}

@include keyframes("blink") {
  0% {
    opacity: .2;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: .2;
  }
}

.loader {
    width: 100%;
    height: 9px;
    background-color: transparent;
    top: 0%;
    left: 0%;
    position: absolute;
}

.loader.on:after {
    content: "";
    position: absolute;
    background-color: $brand-primary;
    top: 2px;
    bottom: 2px;

    -webkit-animation: slide 2s linear infinite;
    -moz-animation: slide 2s linear infinite;
    -o-animation: slide 2s linear infinite;
    animation: slide 2s linear infinite;

}

/**
 * Saving animation - three blinking dots.
 * Needs an element which have three spans (with dots probably) inside.
 * You can have a look at register button to see it in action.
 * https://martinwolf.org/blog/2015/01/pure-css-savingloading-dots-animation
 */
.saving-dots span {
    -webkit-animation: blink 1.5s infinite both; /* Safari 4+ */
    -moz-animation:    blink 1.5s infinite both; /* Fx 5+ */
    -o-animation:      blink 1.5s infinite both; /* Opera 12+ */
    animation:         blink 1.5s infinite both; /* IE 10+ */
    /**
     * animation-fill-mode: both makes sure that the starting style
     * (opacity: .2) of the animation is applied before the animation starts.
     * Otherwise we would see a short flash or would have
     * to set the default styling of the dots to the same
     * as the animation. Same applies for the ending styles.
     */
}

/**
 * Pls see .saving-dots above for explanation.
 */
.saving-dots span:nth-child(2) {
    -webkit-animation-delay: .2s; /* Safari 4+ */
    -moz-animation-delay:    .2s; /* Fx 5+ */
    -o-animation-delay:      .2s; /* Opera 12+ */
    animation-delay:         .2s; /* IE 10+ */
}

/**
 * Pls see .saving-dots above for explanation.
 */
.saving-dots span:nth-child(3) {
    -webkit-animation-delay: .4s; /* Safari 4+ */
    -moz-animation-delay:    .4s; /* Fx 5+ */
    -o-animation-delay:      .4s; /* Opera 12+ */
    animation-delay:         .4s; /* IE 10+ */
}
