@import "icons/variables";
@import "icons/mixins";
@import "icons/path";
@import "icons/core";
@import "icons/bootstrap";
@import "icons/extras";
@import "icons/icons";

.icon-1_2x {
  font-size: 1.2em;
  &.icon-border {
    border-width: 2px;
    @include border-radius(4px);
  }
}
