/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
 * readers do not read off random characters that represent icons */

.icon-glass:before { content: "\f000"; }
.icon-music:before { content: "\f000"; }
.icon-search:before { content: "\f000"; }
.icon-envelope-alt:before { content: "\f000"; }
.icon-heart:before { content: "\f000"; }
.icon-star:before { content: "\f000"; }
.icon-star-empty:before { content: "\f000"; }
.icon-user:before { content: "\f000"; }
.icon-film:before { content: "\f000"; }
.icon-th-large:before { content: "\f000"; }
.icon-th:before { content: "\f000"; }
.icon-th-list:before { content: "\f000"; }
.icon-ok:before { content: "\f000"; }
.icon-remove:before { content: $emicon-del; }
.icon-zoom-in:before { content: "\f000"; }
.icon-zoom-out:before { content: "\f000"; }
.icon-power-off:before,
.icon-off:before { content: "\f000"; }
.icon-signal:before { content: "\f000"; }
.icon-gear:before,
.icon-cog:before { content: $emicon-s; }
.icon-trash:before { content: $emicon-del; }
.icon-home:before { content: "\f000"; }
.icon-file-alt:before { content: "\f000"; }
.icon-time:before { content: "\f000"; }
.icon-road:before { content: "\f000"; }
.icon-download-alt:before { content: "\f000"; }
.icon-download:before { content: "\f000"; }
.icon-upload:before { content: "\f000"; }
.icon-inbox:before { content: "\f000"; }
.icon-play-circle:before { content: "\f000"; }
.icon-rotate-right:before,
.icon-repeat:before { content: "\f000"; }
.icon-refresh:before { content: "\f000"; }
.icon-list-alt:before { content: "\f000"; }
.icon-lock:before { content: "\f000"; }
.icon-flag:before { content: "\f000"; }
.icon-headphones:before { content: "\f000"; }
.icon-volume-off:before { content: "\f000"; }
.icon-volume-down:before { content: "\f000"; }
.icon-volume-up:before { content: "\f000"; }
.icon-qrcode:before { content: "\f000"; }
.icon-barcode:before { content: "\f000"; }
.icon-tag:before { content: "\f000"; }
.icon-tags:before { content: "\f000"; }
.icon-book:before { content: "\f000"; }
.icon-bookmark:before { content: "\f000"; }
.icon-print:before { content: "\f000"; }
.icon-camera:before { content: "\f000"; }
.icon-font:before { content: "\f000"; }
.icon-bold:before { content: "\f000"; }
.icon-italic:before { content: "\f000"; }
.icon-text-height:before { content: "\f000"; }
.icon-text-width:before { content: "\f000"; }
.icon-align-left:before { content: "\f000"; }
.icon-align-center:before { content: "\f000"; }
.icon-align-right:before { content: "\f000"; }
.icon-align-justify:before { content: "\f000"; }
.icon-list:before { content: "\f000"; }
.icon-indent-left:before { content: "\f000"; }
.icon-indent-right:before { content: "\f000"; }
.icon-facetime-video:before { content: "\f000"; }
.icon-picture:before { content: "\f000"; }
.icon-pencil:before { content: $emicon-u; }
.icon-map-marker:before { content: "\f000"; }
.icon-adjust:before { content: "\f000"; }
.icon-tint:before { content: "\f000"; }
.icon-edit:before { content: $emicon-u; }
.icon-share:before { content: "\f000"; }
.icon-check:before { content: "\f000"; }
.icon-move:before { content: $emicon-move; }
.icon-step-backward:before { content: "\f000"; }
.icon-fast-backward:before { content: "\f000"; }
.icon-backward:before { content: "\f000"; }
.icon-play:before { content: "\f000"; }
.icon-pause:before { content: "\f000"; }
.icon-stop:before { content: "\f000"; }
.icon-forward:before { content: "\f000"; }
.icon-fast-forward:before { content: "\f000"; }
.icon-step-forward:before { content: "\f000"; }
.icon-eject:before { content: "\f000"; }
.icon-chevron-left:before { content: "\f000"; }
.icon-chevron-right:before { content: "\f000"; }
.icon-plus-sign:before { content: "\f000"; }
.icon-minus-sign:before { content: "\f000"; }
.icon-remove-sign:before { content: "\f000"; }
.icon-ok-sign:before { content: "\f000"; }
.icon-question-sign:before { content: $emicon-help2; }
.icon-info-sign:before { content: "\f000"; }
.icon-screenshot:before { content: "\f000"; }
.icon-remove-circle:before { content: "\f000"; }
.icon-ok-circle:before { content: "\f000"; }
.icon-ban-circle:before { content: "\f000"; }
.icon-arrow-left:before { content: $emicon-p2; }
.icon-arrow-right:before { content: $emicon-n2; }
.icon-arrow-up:before { content: "\f000"; }
.icon-arrow-up-right:before { content: $emicon-plus; }
.icon-arrow-down:before { content: "\f000"; }
.icon-arrow-down-right:before { content: $emicon-minus; }
.icon-mail-forward:before,
.icon-share-alt:before { content: "\f000"; }
.icon-resize-full:before { content: "\f000"; }
.icon-resize-small:before { content: "\f000"; }
.icon-plus:before { content: $emicon-new; }
.icon-minus:before { content: "\f000"; }
.icon-asterisk:before { content: "\f000"; }
.icon-exclamation-sign:before { content: "\f000"; }
.icon-gift:before { content: "\f000"; }
.icon-leaf:before { content: "\f000"; }
.icon-fire:before { content: "\f000"; }
.icon-eye-open:before { content: $emicon-v; }
.icon-eye-close:before { content: "\f000"; }
.icon-warning-sign:before { content: "\f000"; }
.icon-plane:before { content: "\f000"; }
.icon-calendar:before { content: $emicon-k; }
.icon-random:before { content: "\f000"; }
.icon-comment:before { content: "\f000"; }
.icon-magnet:before { content: "\f000"; }
.icon-chevron-up:before { content: "\f000"; }
.icon-chevron-down:before { content: "\f000"; }
.icon-retweet:before { content: "\f000"; }
.icon-shopping-cart:before { content: "\f000"; }
.icon-folder-close:before { content: "\f000"; }
.icon-folder-open:before { content: "\f000"; }
.icon-resize-vertical:before { content: "\f000"; }
.icon-resize-horizontal:before { content: "\f000"; }
.icon-bar-chart:before { content: "\f000"; }
.icon-twitter-sign:before { content: "\f000"; }
.icon-facebook-sign:before { content: "\f000"; }
.icon-camera-retro:before { content: "\f000"; }
.icon-key:before { content: "\f000"; }
.icon-gears:before,
.icon-cogs:before { content: "\f000"; }
.icon-comments:before { content: "\f000"; }
.icon-thumbs-up-alt:before { content: "\f000"; }
.icon-thumbs-down-alt:before { content: "\f000"; }
.icon-star-half:before { content: "\f000"; }
.icon-heart-empty:before { content: "\f000"; }
.icon-signout:before { content: "\f000"; }
.icon-linkedin-sign:before { content: "\f000"; }
.icon-pushpin:before { content: "\f000"; }
.icon-external-link:before { content: "\f000"; }
.icon-signin:before { content: "\f000"; }
.icon-trophy:before { content: "\f000"; }
.icon-github-sign:before { content: "\f000"; }
.icon-upload-alt:before { content: "\f000"; }
.icon-lemon:before { content: "\f000"; }
.icon-phone:before { content: "\f000"; }
.icon-unchecked:before,
.icon-check-empty:before { content: "\f000"; }
.icon-bookmark-empty:before { content: "\f000"; }
.icon-phone-sign:before { content: "\f000"; }
.icon-twitter:before { content: "\f000"; }
.icon-facebook:before { content: "\f000"; }
.icon-github:before { content: "\f000"; }
.icon-unlock:before { content: "\f000"; }
.icon-credit-card:before { content: "\f000"; }
.icon-rss:before { content: "\f000"; }
.icon-hdd:before { content: "\f000"; }
.icon-bullhorn:before { content: "\f000"; }
.icon-bell:before { content: $emicon-r; }
.icon-certificate:before { content: "\f000"; }
.icon-hand-right:before { content: "\f000"; }
.icon-hand-left:before { content: "\f000"; }
.icon-hand-up:before { content: "\f000"; }
.icon-hand-down:before { content: "\f000"; }
.icon-circle-arrow-left:before { content: "\f000"; }
.icon-circle-arrow-right:before { content: "\f000"; }
.icon-circle-arrow-up:before { content: "\f000"; }
.icon-circle-arrow-down:before { content: "\f000"; }
.icon-globe:before { content: $emicon-a; }
.icon-wrench:before { content: "\f000"; }
.icon-tasks:before { content: "\f000"; }
.icon-filter:before { content: "\f000"; }
.icon-briefcase:before { content: "\f000"; }
.icon-fullscreen:before { content: "\f000"; }
.icon-group:before { content: "\f000"; }
.icon-link:before { content: "\f000"; }
.icon-cloud:before { content: "\f000"; }
.icon-beaker:before { content: "\f000"; }
.icon-cut:before { content: "\f000"; }
.icon-copy:before { content: "\f000"; }
.icon-paperclip:before,
.icon-paper-clip:before { content: "\f000"; }
.icon-save:before { content: "\f000"; }
.icon-sign-blank:before { content: "\f000"; }
.icon-reorder:before { content: "\f000"; }
.icon-list-ul:before { content: "\f000"; }
.icon-list-ol:before { content: "\f000"; }
.icon-strikethrough:before { content: "\f000"; }
.icon-underline:before { content: "\f000"; }
.icon-table:before { content: "\f000"; }
.icon-magic:before { content: "\f000"; }
.icon-truck:before { content: "\f000"; }
.icon-pinterest:before { content: "\f000"; }
.icon-pinterest-sign:before { content: "\f000"; }
.icon-google-plus-sign:before { content: "\f000"; }
.icon-google-plus:before { content: "\f000"; }
.icon-money:before { content: "\f000"; }
.icon-caret-down:before { content: $emicon-o; }
.icon-caret-up:before { content: $emicon-c; }
.icon-caret-left:before { content: "\f000"; }
.icon-caret-right:before { content: "\f000"; }
.icon-columns:before { content: "\f000"; }
.icon-sort:before { content: "\f000"; }
.icon-sort-down:before { content: "\f000"; }
.icon-sort-up:before { content: "\f000"; }
.icon-envelope:before { content: "\f000"; }
.icon-linkedin:before { content: "\f000"; }
.icon-rotate-left:before,
.icon-undo:before { content: "\f000"; }
.icon-legal:before { content: "\f000"; }
.icon-dashboard:before { content: "\f000"; }
.icon-comment-alt:before { content: "\f000"; }
.icon-comments-alt:before { content: "\f000"; }
.icon-bolt:before { content: "\f000"; }
.icon-sitemap:before { content: "\f000"; }
.icon-umbrella:before { content: "\f000"; }
.icon-paste:before { content: "\f000"; }
.icon-lightbulb:before { content: "\f000"; }
.icon-exchange:before { content: "\f000"; }
.icon-cloud-download:before { content: "\f000"; }
.icon-cloud-upload:before { content: "\f000"; }
.icon-user-md:before { content: "\f000"; }
.icon-stethoscope:before { content: "\f000"; }
.icon-suitcase:before { content: "\f000"; }
.icon-bell-alt:before { content: $emicon-r2; }
.icon-coffee:before { content: "\f000"; }
.icon-food:before { content: "\f000"; }
.icon-file-text-alt:before { content: "\f000"; }
.icon-building:before { content: "\f000"; }
.icon-hospital:before { content: "\f000"; }
.icon-ambulance:before { content: "\f000"; }
.icon-medkit:before { content: "\f000"; }
.icon-fighter-jet:before { content: "\f000"; }
.icon-beer:before { content: "\f000"; }
.icon-h-sign:before { content: "\f000"; }
.icon-plus-sign-alt:before { content: "\f000"; }
.icon-double-angle-left:before { content: "\f000"; }
.icon-double-angle-right:before { content: "\f000"; }
.icon-double-angle-up:before { content: "\f000"; }
.icon-double-angle-down:before { content: "\f000"; }
.icon-angle-left:before { content: "\f000"; }
.icon-angle-right:before { content: "\f000"; }
.icon-angle-up:before { content: "\f000"; }
.icon-angle-down:before { content: "\f000"; }
.icon-desktop:before { content: "\f000"; }
.icon-laptop:before { content: "\f000"; }
.icon-tablet:before { content: "\f000"; }
.icon-mobile-phone:before { content: "\f000"; }
.icon-circle-blank:before { content: "\f000"; }
.icon-quote-left:before { content: "\f000"; }
.icon-quote-right:before { content: "\f000"; }
.icon-spinner:before { content: "\f000"; }
.icon-circle:before { content: $emicon-b2; }
.icon-mail-reply:before,
.icon-reply:before { content: "\f000"; }
.icon-github-alt:before { content: "\f000"; }
.icon-folder-close-alt:before { content: "\f000"; }
.icon-folder-open-alt:before { content: "\f000"; }
.icon-expand-alt:before { content: "\f000"; }
.icon-collapse-alt:before { content: "\f000"; }
.icon-smile:before { content: "\f000"; }
.icon-frown:before { content: "\f000"; }
.icon-meh:before { content: "\f000"; }
.icon-gamepad:before { content: "\f000"; }
.icon-keyboard:before { content: "\f000"; }
.icon-flag-alt:before { content: "\f000"; }
.icon-flag-checkered:before { content: "\f000"; }
.icon-terminal:before { content: "\f000"; }
.icon-code:before { content: "\f000"; }
.icon-reply-all:before { content: "\f000"; }
.icon-mail-reply-all:before { content: "\f000"; }
.icon-star-half-full:before,
.icon-star-half-empty:before { content: "\f000"; }
.icon-location-arrow:before { content: "\f000"; }
.icon-crop:before { content: "\f000"; }
.icon-code-fork:before { content: "\f000"; }
.icon-unlink:before { content: "\f000"; }
.icon-question:before { content: "\f000"; }
.icon-info:before { content: "\f000"; }
.icon-exclamation:before { content: "\f000"; }
.icon-superscript:before { content: "\f000"; }
.icon-subscript:before { content: "\f000"; }
.icon-eraser:before { content: "\f000"; }
.icon-puzzle-piece:before { content: "\f000"; }
.icon-microphone:before { content: "\f000"; }
.icon-microphone-off:before { content: "\f000"; }
.icon-shield:before { content: "\f000"; }
.icon-calendar-empty:before { content: "\f000"; }
.icon-fire-extinguisher:before { content: "\f000"; }
.icon-rocket:before { content: "\f000"; }
.icon-maxcdn:before { content: "\f000"; }
.icon-chevron-sign-left:before { content: "\f000"; }
.icon-chevron-sign-right:before { content: "\f000"; }
.icon-chevron-sign-up:before { content: "\f000"; }
.icon-chevron-sign-down:before { content: "\f000"; }
.icon-html5:before { content: "\f000"; }
.icon-css3:before { content: "\f000"; }
.icon-anchor:before { content: "\f000"; }
.icon-unlock-alt:before { content: "\f000"; }
.icon-bullseye:before { content: "\f000"; }
.icon-ellipsis-horizontal:before { content: "\f000"; }
.icon-ellipsis-vertical:before { content: "\f000"; }
.icon-rss-sign:before { content: "\f000"; }
.icon-play-sign:before { content: "\f000"; }
.icon-ticket:before { content: "\f000"; }
.icon-minus-sign-alt:before { content: "\f000"; }
.icon-check-minus:before { content: "\f000"; }
.icon-level-up:before { content: "\f000"; }
.icon-level-down:before { content: "\f000"; }
.icon-check-sign:before { content: "\f000"; }
.icon-edit-sign:before { content: "\f000"; }
.icon-external-link-sign:before { content: "\f000"; }
.icon-share-sign:before { content: "\f000"; }
.icon-compass:before { content: "\f000"; }
.icon-collapse:before { content: "\f000"; }
.icon-collapse-top:before { content: "\f000"; }
.icon-expand:before { content: "\f000"; }
.icon-euro:before,
.icon-eur:before { content: "\f000"; }
.icon-gbp:before { content: "\f000"; }
.icon-dollar:before,
.icon-usd:before { content: "\f000"; }
.icon-rupee:before,
.icon-inr:before { content: "\f000"; }
.icon-yen:before,
.icon-jpy:before { content: "\f000"; }
.icon-renminbi:before,
.icon-cny:before { content: "\f000"; }
.icon-won:before,
.icon-krw:before { content: "\f000"; }
.icon-bitcoin:before,
.icon-btc:before { content: "\f000"; }
.icon-file:before { content: "\f000"; }
.icon-file-text:before { content: "\f000"; }
.icon-sort-by-alphabet:before { content: "\f000"; }
.icon-sort-by-alphabet-alt:before { content: "\f000"; }
.icon-sort-by-attributes:before { content: "\f000"; }
.icon-sort-by-attributes-alt:before { content: "\f000"; }
.icon-sort-by-order:before { content: "\f000"; }
.icon-sort-by-order-alt:before { content: "\f000"; }
.icon-thumbs-up:before { content: "\f000"; }
.icon-thumbs-down:before { content: "\f000"; }
.icon-youtube-sign:before { content: "\f000"; }
.icon-youtube:before { content: "\f000"; }
.icon-xing:before { content: "\f000"; }
.icon-xing-sign:before { content: "\f000"; }
.icon-youtube-play:before { content: "\f000"; }
.icon-dropbox:before { content: "\f000"; }
.icon-stackexchange:before { content: "\f000"; }
.icon-instagram:before { content: "\f000"; }
.icon-flickr:before { content: "\f000"; }
.icon-adn:before { content: "\f000"; }
.icon-bitbucket:before { content: "\f000"; }
.icon-bitbucket-sign:before { content: "\f000"; }
.icon-tumblr:before { content: "\f000"; }
.icon-tumblr-sign:before { content: "\f000"; }
.icon-long-arrow-down:before { content: "\f000"; }
.icon-long-arrow-up:before { content: "\f000"; }
.icon-long-arrow-left:before { content: "\f000"; }
.icon-long-arrow-right:before { content: "\f000"; }
.icon-apple:before { content: "\f000"; }
.icon-windows:before { content: "\f000"; }
.icon-android:before { content: "\f000"; }
.icon-linux:before { content: "\f000"; }
.icon-dribbble:before { content: "\f000"; }
.icon-skype:before { content: "\f000"; }
.icon-foursquare:before { content: "\f000"; }
.icon-trello:before { content: "\f000"; }
.icon-female:before { content: "\f000"; }
.icon-male:before { content: "\f000"; }
.icon-gittip:before { content: "\f000"; }
.icon-sun:before { content: "\f000"; }
.icon-moon:before { content: "\f000"; }
.icon-archive:before { content: "\f000"; }
.icon-bug:before { content: "\f000"; }
.icon-vk:before { content: "\f000"; }
.icon-weibo:before { content: "\f000"; }
.icon-renren:before { content: "\f000"; }
