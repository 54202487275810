// Variables
// --------------------------

$emIconFontPath: ".." !default;
$emIconFontVersion: "erofe4" !default;
$borderColor: #eeeeee !default;
$iconMuted: #eeeeee !default;
$iconLight: white !default;
$iconDark: #333333 !default;
$icons-li-width: (30em/14);

$emicon-a: "\61";
$emicon-a2: "\41";
$emicon-b: "\62";
$emicon-b2: "\42";
$emicon-c: "\63";
$emicon-c2: "\43";
$emicon-c3: "\10c";
$emicon-k: "\6b";
$emicon-d: "\64";
$emicon-d2: "\44";
$emicon-del: "\2d";
$emicon-e: "\65";
$emicon-e2: "\45";
$emicon-f: "\e901";
$emicon-f2: "\e900";
$emicon-g: "\67";
$emicon-g2: "\47";
$emicon-h: "\68";
$emicon-h2: "\48";
$emicon-help: "\3f";
$emicon-help2: "\24";
$emicon-l: "\6c";
$emicon-m: "\6d";
$emicon-m2: "\4d";
$emicon-minus: "\3c";
$emicon-move: "\23";
$emicon-n: "\6e";
$emicon-n2: "\4e";
$emicon-new: "\2b";
$emicon-o: "\6f";
$emicon-o2: "\4f";
$emicon-p: "\70";
$emicon-p2: "\50";
$emicon-plus: "\3e";
$emicon-r: "\72";
$emicon-r2: "\52";
$emicon-s: "\73";
$emicon-t: "\74";
$emicon-t2: "\54";
$emicon-u: "\75";
$emicon-v: "\76";
$emicon-w: "\77";
$emicon-w2: "\57";
$emicon-x: "\78";
$emicon-x2: "\58";
$emicon-y: "\79";
$emicon-y2: "\59";
