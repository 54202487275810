$context-color: (
  "dashboard": #009cdc,
  "electricity": #e86d61,
  "gas": #eecb23,
  "water": #0055b2,
  "temperature": #ee5c77,
  "co2": #9dadbc,
  "humidity": #6ab050,
  "plugsense": #e86d61,
  "display": #000000,
);

$context-color-previous: (
  "dashboard": #00bcff,
  "electricity": #ff7064,
  "gas": #ffdb24,
  "water": #006dff,
  "temperature": #ff6085,
  "co2": #bbcedd,
  "humidity": #85da5f,
  "plugsense": #ff7064,
  "display": #000000,
);

$medium: (
  "dashboard": $emicon-l,
  "electricity": $emicon-e,
  "gas": $emicon-g,
  "water": $emicon-w,
  "temperature": $emicon-t,
  "co2": $emicon-f,
  "humidity": $emicon-h,
  "plugsense": $emicon-c,
);

$medium-active: (
  "dashboard": $emicon-l,
  "electricity": $emicon-e2,
  "gas": $emicon-g2,
  "water": $emicon-w2,
  "temperature": $emicon-t2,
  "co2": $emicon-f2,
  "humidity": $emicon-h2,
  "plugsense": $emicon-c2,
);


$gray60: #666666 !default;
$gray40: #989898 !default;
$gray30: #B0B0B0 !default;
$gray20: #CBCBCB !default;
$gray10: #E4E4E4 !default;
$weight-light:       300;
$weight-regular:     400;
$weight-semibold:    700;
$weight-bold:        700;
$weight-black:       900;

$custom-brand-primary: #007db8;

// This is sort of a grid width used inside the charts
// Margins are multiples of this value
// Make sure this is in sync with the value inside charts/base.coffee
$chart-internal-square: 12px;

@function color($key) {
  @if map-has-key($context-color, $key) {
    @return map-get($context-color, $key);
  }

  @warn "Unknown `#{$key}` in $context-color.";
  @return null;
}

@function color-previous($key) {
  @if map-has-key($context-color-previous, $key) {
    @return map-get($context-color-previous, $key);
  }

  @warn "Unknown `#{$key}` in $context-color-previous.";
  @return null;
}

@function medium($key) {
  @if map-has-key($medium, $key) {
    @return map-get($medium, $key);
  }

  @warn "Unknown `#{$key}` in $medium.";
  @return null;
}

@function medium-active($key) {
  @if map-has-key($medium-active, $key) {
    @return map-get($medium-active, $key);
  }

  @warn "Unknown `#{$key}` in $medium-active.";
  @return null;
}
