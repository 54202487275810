$panel_title_font_size: 42px;

@each $medium_key, $medium_value in $medium {

    .widget-#{$medium_key}-panel {
        margin-left: -$navbar-padding-horizontal;

        .title {
            float: left;
            margin-bottom: 0;

            h2 {
                color: color($medium_key);
                font-size: $panel_title_font_size;
            }
        }

        .switcher,
        .feeds {
            @include media(xs) { margin-left: 5px; }
            @include media(sm) { margin-left: 10px; }
            @include media(lg) { margin-left: 25px; }
        }

        .pull-right {
            @include media(xs) {
                float: left !important;
                margin-left: 5px;
            }
            @include media(sm) {
                float: left !important;
                margin-left: 10px;
            }
            @include media(md) { float: right !important; }
        }

        .wrapper {
            height: floor(($panel_title_font_size * $headings-line-height));
            padding-top: .5em;
        }

        .switcher .btn.btn-default {
            background-color: $gray10;
            border-color: $gray20;
            border-radius: 10px;
            color: #B1B3B4;

            &:first-child:not(:last-child):not(.dropdown-toggle) {
                border-bottom-right-radius: 0;
                border-top-right-radius: 0;
            }

            &:last-child:not(:first-child),
            &.dropdown-toggle:not(:first-child) {
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
            }

            &:active,
            &.active {
                outline: 0;
                background-image: none;
                background-color: #FFFFFF;
                color: color($medium_key);
                @include box-shadow(none);
            }
        }

        .dates .open > .dropdown-menu {
            left: inherit;
            right: 0;
        }

        .dates .btn,
        .dates .open .btn {
            background-color: transparent;
            border: none;
            @include box-shadow(none);

            font-weight: $weight-black;
            font-size: 18px;
            padding: 5px 0 0 0;
            color: #000000;

            &:active,
            &.active {
                outline: 0;
                background-image: none;
                color: color($medium_key);
                @include box-shadow(none);
            }
        }

        .dates .btn.dates-back {
            margin-left: 1em;
        }
        .dates .btn-group {
            white-space: nowrap;
        }

        .dates-next i { padding-top: 0; padding-bottom: 0; }
        .dates-next i.icon-arrow-right:before { color: color($medium_key); font-size: 34px; }
        .dates-back i.icon-arrow-left:before { color: color($medium_key); font-size: 34px; }

        .dates-next.disabled i.icon-arrow-right:before,
        .dates-back.disabled i.icon-arrow-left:before { color: #b1b3b4; }
    }

}
