// Settings, exports, notifications etc.
#top-navigation {
    padding-bottom: $navbar-padding-vertical;
    margin: 0;
    z-index: 1001;

    .navbar-right {
        margin: 1em 0;
    }

    .navbar-nav li a {
        border-radius: 0;
        border: 1px solid transparent;
    }

    .navbar-right > li > a {
        @include button-size($padding-xs-vertical, $padding-xs-horizontal, $font-size-base, $line-height-base, $border-radius-large);
        margin: 0 $padding-base-horizontal;
        font-weight: $weight-light;
        font-size: 18px;
        letter-spacing: 0.025em;
    }

    .navbar-right > li > a.active {
        color: $component-active-color;
        background-color: $brand-primary;
        font-size: 18px;
        font-weight: $weight-bold;
        letter-spacing: -0.025em;
    }

    .navbar-right > li.dropdown.open > a.dropdown-toggle {
        background-color: $dropdown-bg;
        border-radius: $border-radius-large $border-radius-large 0 0;
        @include media(xs) { border-radius: 0; }
        border-top: 1px solid $dropdown-border;
        border-right: 1px solid $dropdown-border;
        border-bottom: 1px solid transparent;
        border-left: 1px solid $dropdown-border;
        color: $gray40;
    }

    .navbar-right > li.dropdown.active.open > a.dropdown-toggle {
        background-color: $brand-primary;
        color: #FFFFFF;
        font-weight: $weight-bold;
        letter-spacing: -0.025em;
    }

    .navbar-right > li.dropdown.open .dropdown-menu a.active {
        color: #000000;
        font-weight: $weight-bold;
        letter-spacing: -0.025em;
    }

    .navbar-right > li.dropdown.open .dropdown-menu a {
        color: #000000;
    }

    .navbar-right > li.dropdown.open .dropdown-menu a:hover {
        color: $brand-primary;
        font-weight: $weight-bold;
        letter-spacing: -0.025em;
    }

    .navbar-right > li > a:last-of-type {
        margin-right: 0;
    }

}

.navbar-brand {
    float: left;
    font-size: 18px;
    text-indent: -9999px;
    background: url(../web_cscan_logo-light.svg) $navbar-padding-horizontal top no-repeat;
    background-size: contain;
    width: 170px + $navbar-padding-horizontal;
    height: 66px;
}

.mod-no-svg .navbar-brand {
    float: left;
    font-size: 18px;
    text-indent: -9999px;
    background: url(../web_cscan_logo_180x66-light.png) $navbar-padding-horizontal top no-repeat;
    width: 170px + $navbar-padding-horizontal;
    height: 66px;
}

.navbar,
.navbar-default,
.navbar-static-top {
    border: none;
}

#navigation {
    border-bottom: 1px solid $gray30;
}

@each $medium_key, $medium_color in $context-color {

    #navigation.#{$medium_key} {
        border-bottom: 1px solid $medium_color;
    }

}

#navigation .container {
    padding-left: 0;
}

.navbar-default .navbar-nav li a {
    [class^="icon-"],
    [class*=" icon-"] {
        display: inline-block;
        line-height: inherit;
        vertical-align: bottom;
        margin-right: $padding-xs-horizontal;
    }

    .icon-2x { font-size: 32px; }

    border-radius: $border-radius-large $border-radius-large 0 0;

    @include media(xs) { border-radius: 0; }

    font-size: 16px;
    font-weight: $weight-light;
    letter-spacing: 0.025em;
    color: $gray40;
    padding-left: $padding-base-horizontal;
    padding-right: $padding-base-horizontal;
}

// Panels with dashboard and media detailed views
.navbar-default .navbar-nav > li > a {

    &#menu-dashboard {
        &:hover { color: color('dashboard'); }
        i:before { display: none; }
        i { display: none; }

        @include media(xs) {
            padding-left: 3em;
        }
    }
    &#menu-electricity{
        &:hover { color: color('electricity'); }
        i:before { content: medium('electricity'); }
    }
    &#menu-gas{
        &:hover { color: color('gas'); }
        i:before { content: medium('gas'); }
    }
    &#menu-water{
        &:hover { color: color('water'); }
        i:before { content: medium('water'); }
    }
    &#menu-temperature{
        &:hover { color: color('temperature'); }
        i:before { content: medium('temperature'); }
    }
    &#menu-co2{
        &:hover { color: color('co2'); }
        i:before { content: medium('co2'); }
    }
    &#menu-humidity{
        &:hover { color: color('humidity'); }
        i:before { content: medium('humidity'); }
    }
}

.navbar-default .navbar-nav > li.active > a {
    &#menu-dashboard {
        background-color: color('dashboard');
    }
    &#menu-electricity {
        background-color: color('electricity');
        i:before { content: medium-active('electricity'); }
    }
    &#menu-gas {
        background-color: color('gas');
        i:before { content: medium-active('gas'); }
    }
    &#menu-water {
        background-color: color('water');
        i:before { content: medium-active('water'); }
    }
    &#menu-temperature {
        background-color: color('temperature');
        i:before { content: medium-active('temperature'); }
    }
    &#menu-co2 {
        background-color: color('co2');
        i:before { content: medium-active('co2'); }
    }
    &#menu-humidity {
        background-color: color('humidity');
        i:before { content: medium-active('humidity'); }
    }
    font-size: 16px;
    font-weight: $weight-bold;
    letter-spacing: -0.02em;
}

.navbar-default .navbar-nav > li.active > a {
    &#menu-dashboard:hover { color: $component-active-color; }
    &#menu-electricity:hover { color: $component-active-color; }
    &#menu-gas:hover { color: $component-active-color; }
    &#menu-water:hover { color: $component-active-color; }
    &#menu-temperature:hover { color: $component-active-color; }
    &#menu-co2:hover { color: $component-active-color; }
    &#menu-humidity:hover { color: $component-active-color; }
}
