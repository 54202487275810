@each $medium_key, $medium_value in $medium {

.widget-#{$medium_key}-detail,
.widget-#{$medium_key}-comparison,
.widget-#{$medium_key}-heatmap {

    .widget-content-block {
      padding-bottom: 15px;
    }
    
    .widget-header-icon {
        color: color($medium_key);

        &:before {
            @include unicode_content(medium($medium_key));
        }
    }

    h2 {
        color: lighten(color($medium_key), 20%);
        em { font-weight: $weight-light; color: $gray30; font-size: 24px; font-style: normal; }
    }

    // Color for widget type Detail/Comparison/Heatmap
    h2 .widget-type-label {
        color: #000000;
    }

    h2 .period {
        font-weight: $weight-bold;
        font-size: 24px;

        &:before { content: "\2f"; margin-right: .25em; font-weight: $weight-light; color: $gray30; }
        &:after { content: "\2f"; margin-left: .25em; font-weight: $weight-light; color: $gray30; }

        &.actual { color: color($medium_key); }
        &.previous { color: color-previous($medium_key); }

        .day-of-week { font-weight: $weight-light; }
    }
}

}

/*
    Checkboxes/radio inputs above charts
*/

.control {
    margin-top: 1em;
}
.extra-control > div {
    margin-top: 15px;
    margin-bottom: 5px;
}
.control label,
.extra-control label {
    font-size: 12px;
    margin-right:1em;
    color: $gray-light;
    border: 1px solid transparent;
    border-radius: 4px;
    font-weight: $weight-bold;
    cursor: pointer;

    svg {
        width: 20px;
        height: 20px;
        margin-bottom: -0.5em;
        margin-right: 5px;
    }

    input[type=checkbox],
    input[type=radio] {
        visibility: hidden;
        display: none;
    }
}
.control label.inactive.rule_them_all,
.control label.inactive.rule_them_all:hover {
    border: 1px solid $gray60;
    color: $gray60;
    float: right;
}

.extra-control label.extracheckbox {
    color: $gray40;

    path.checkbox-mark,
    path.radio-mark {
        fill: $gray60;
        fill-opacity: 1;
        fill-rule: nonzero;
        stroke: none;
    }
    path.checkbox-border,
    path.radio-border {
        fill: $gray10;
        stroke: $gray30;
        stroke-width: 1;
        stroke-linecap: butt;
        stroke-linejoin: miter;
        stroke-miterlimit: 4;
        stroke-dasharray: none;
        stroke-opacity: 1;
    }
}

.extra-control label.extracheckbox.inactive {
    color: $gray40;

    path.checkbox-mark,
    path.radio-mark {
        fill: #FFFFFF;
        fill-opacity: 1;
        fill-rule: nonzero;
        stroke: none;
    }

    path.checkbox-border,
    path.radio-border {
        fill: $gray10;
        stroke: $gray30;
        stroke-width: 1;
        stroke-linecap: butt;
        stroke-linejoin: miter;
        stroke-miterlimit: 4;
        stroke-dasharray: none;
        stroke-opacity: 1;
    }
}


@each $color_index, $color_value in $streams {
    .control label.color-#{$color_index} {
        color: $color_value;
        border-color: $color_value;
        background-color: #FFFFFF;

        path.checkbox-mark,
        path.radio-mark {
            fill: $color_value;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
        }
        path.checkbox-border,
        path.radio-border {
            fill: none;
            stroke: $gray30;
            stroke-width: 1;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
        }

    }

    .control label.inactive.color-#{$color_index} {
        color: #888;
        border-color: transparent;
        background-color: transparent;

        &:hover {
            background-color: #FFFFFF;
        }

        path.checkbox-mark,
        path.radio-mark {
            fill: #FFFFFF;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
        }

        path.checkbox-border,
        path.radio-border {
            fill: $gray10;
            stroke: $gray30;
            stroke-width: 1;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
        }

    }

    .control label {
        padding: 8px 10px;
    }

    .control label.color-#{$color_index}:hover {
        border: 1px solid $color_value;
        color: $color_value;
    }
}
