$chart_margin_left: 72px;
$status_base_font_size: 62px;

$status_xs_font_size: floor(0.5  * $status_base_font_size);
$status_sm_font_size: floor(0.6  * $status_base_font_size);
$status_md_font_size: floor(0.9  * $status_base_font_size);
$status_lg_font_size: floor(1    * $status_base_font_size);

#slider {
    overflow-y: hidden;
    top: -1px;
    background-color: darken($body-bg,2%);
    max-height: 800px; /* approximate max height */
    padding-bottom: $padding-large-vertical;

    -webkit-transition-property: max-height;
    -moz-transition-property: max-height;
    -o-transition-property: max-height;
    transition-property: max-height;

    -webkit-transition-timing-function: ease-out;
    -moz-transition-timing-function: ease-out;
    -o-transition-timing-function: ease-out;
    transition-timing-function: ease-out;

    -webkit-transition-duration: .5s;
    -moz-transition-duration: .5s;
    -o-transition-duration: .5s;
    transition-duration: .5s;

    -webkit-transition-delay: 10ms;
    -moz-transition-delay: 10ms;
    -o-transition-delay: 10ms;
    transition-delay: 10ms;

    -webkit-transform: translate3d(0,0,0);
    -moz-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    -o-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}

.closed #slider {
    max-height: 0px;
    padding-bottom: 0;
}

.feature-help {

    .description {
        h2 { font-size: ($font-size-base * 1.75); }
        h3 { font-size: ($font-size-base * 1.15); }
        p { @extend .text-muted; }
    }

    .screenshot {
        float: right;

        img { max-width: 100%; height: auto; }
    }

}

.feature-help .wrapper {

    @include media(xs) {
        .pull-right {
            margin-right: $padding-base-horizontal;
        }
    }

    &.closed {
        overflow-y: hidden;

        #slider-button {
            display: none;
        }

        #slider-button-closed {
            display: block;
        }
    }

    #slider-button-closed {
        display: none;
    }
}

#content.savings {
    margin-top: 0;
}

.savings {
    #slider-button,
    #slider-button-closed {
        text-decoration: none;
        cursor: pointer;
    }


    #slider-button {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-top: 0;
    }

    .status {
        .alert {
            width: 90%;
            margin-left: 5%;
            margin-right: 5%;
        }
        // i.icon-question-sign,
        // i.icon-question {
        //     margin-left: 1em;
        //     color: $text-muted;
        // }
    }

    [data-hook=chart] {
        & > div {
            position: relative;
        }
        .alert {
            position: absolute;
            top: 35%;
            left: 5%;
            width: 90%;
            z-index: 1;
        }
    }

    .widget-content-block {
        margin: $font-size-h2 0 ;
    }
}

.svg-wrap {
    height: 0;
    padding-bottom: 100%; /* needs to be overwritten using inline style for everything else than square. see BaseChart for implementation */
    position: relative;
    svg {
        position: absolute;
        top: 0;
        left: 0;
    }
}

.status {

    & > div > div > div {
        border: 1px solid $gray30;
        padding: $padding-base-vertical $padding-base-horizontal;
        text-align: right;

        @include media(xs) { min-height: floor( 3    * $status_xs_font_size); }
        @include media(sm) { min-height: floor( 4.5  * $status_sm_font_size); }
        @include media(md) { min-height: floor( 3    * $status_md_font_size); }
        @include media(lg) { min-height: floor( 3    * $status_lg_font_size); }

        @include media(xs) {
            text-align: left;
            border: none;
        }
    }

    p {
        color: #87888a;

        strong {
            font-weight: $weight-bold;
            color: #000000;
            letter-spacing: -0.04em;

            @include media(xs) { font-size: $status_xs_font_size; }
            @include media(sm) { font-size: $status_sm_font_size; }
            @include media(md) { font-size: $status_md_font_size; }
            @include media(lg) { font-size: $status_lg_font_size; }

            em {
                font-weight: $weight-light;
                @include media(xs) { font-size: floor(0.5  * 32px); }
                @include media(sm) { font-size: floor(0.6  * 32px); }
                @include media(md) { font-size: floor(0.9  * 32px); }
                @include media(lg) { font-size: floor(1    * 32px); }
                font-style: normal;
                color: #000000;
            }
        }
    }

    i,
    i:before {
        color: $brand-primary;
    }

    .yesterday {

    }

    .today {

        @include media(xs) {
            .graph { display: none !important; }
        }

    }

    .allyear {

    }

    .today .clearfix {
        position: relative;
    }

    .donut-chart {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    svg { display: block; }
}

.selections {
    margin: $font-size-h2 0 ;
}

.savings [data-hook=periods] .btn-group {
    margin: $font-size-h2 0 $font-size-h2 ($chart_margin_left - $padding-base-horizontal);

    .btn {
        background-color: $gray10;
        border-color: $gray20;
        border-radius: $border-radius-base;
        color: #B1B3B4;

        &:first-child:not(:last-child):not(.dropdown-toggle) {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
        }

        &:last-child:not(:first-child),
        &.dropdown-toggle:not(:first-child) {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
        }

        &:active,
        &.active {
            outline: 0;
            background-image: none;
            background-color: #FFFFFF;
            color: color('electricity');
            @include box-shadow(none);
        }
    }
}

.trend {
    margin: $font-size-h2 0 $font-size-h2 $chart_margin_left;
    color: $brand-primary;
    font-size: $font-size-h4;
}

.trend-graph {
    div {
        margin: 2em auto;
        max-width: 750px;
    }
    img {
        width: 100%;
    }
}


#tips {
    h3 {
        margin-bottom: ($font-size-base * 2.5);
    }

    dt {
        color: #000000;
        margin: $padding-large-vertical 0;
    }
}

.savings .btn-group .btn.btn-default {
    color: #000000;
    font-weight: $weight-regular;
}

.savings .btn-group.feeds-select:before {
    font-size: 32px;
}

.savings .btn-group.feeds-select .btn-default {
    font-size: 18px;
}

.savings .btn-group .btn.btn-default.streams-select {
    color: #000000;
    font-size: 18px;

    &:before {
        @include icon-emIconFont();
        position: absolute;
        top: 0.05em;
        left: 0.05em;
        font-size: 30px;
        line-height: 1;
        color: color('electricity');
        content: medium('electricity');
    }
}


