/*
* SVG charts color variables for light scheme:
*
* These are mostly affecting SVG elements, but certain HTML element aswell (eg. Table rows matching streams)
*
* A lot of stuff uses SASS maps - see http://thesassway.com/news/sass-3-3-released#maps
*/

$color_actual:      #00C800;
$color_previous:    #86B7BA;

/* Color definitions for streams */
$streams: (
    "0-0": #1f77b4,
    "0-1": #aec7e8,
    "0-2": #ff7f0e,
    "0-3": #ffbb78,
    "1-0": #2ca02c,
    "1-1": #98df8a,
    "1-2": #d62728,
    "1-3": #ff9896,
    "2-0": #9467bd,
    "2-1": #c5b0d5,
    "2-2": #8c564b,
    "2-3": #c49c94,
    "3-0": #e377c2,
    "3-1": #f7b6d2,
    "3-2": #7f7f7f,
    "3-3": #c7c7c7,
    "4-0": #bcbd22,
    "4-1": #dbdb8d,
    "4-2": #17becf,
    "4-3": #9edae5,
);

/* Hatches */
$hatches: (
    "0-0": "0_0",
    "0-1": "0_1",
    "0-2": "0_2",
    "0-3": "0_3",
    "1-0": "1_0",
    "1-1": "1_1",
    "1-2": "1_2",
    "1-3": "1_3",
    "2-0": "2_0",
    "2-1": "2_1",
    "2-2": "2_2",
    "2-3": "2_3",
    "3-0": "3_0",
    "3-1": "3_1",
    "3-2": "3_2",
    "3-3": "3_3",
    "4-0": "4_0",
    "4-1": "4_1",
    "4-2": "4_2",
    "4-3": "4_3",
);

/* Settings section variables */
$switching-bg: #333333;

/* Base chart */
$base-chart: (
    axis-stroke-color: #C8C7C5,
    axis-opacity: 1,
    axis-text-color: #605D5D,
    odd-week-fill: lighten($brand-primary, 20%),
    odd-week-opacity: .06,
    bubble-opacity: .6,
    bubble-bg: #FFFFFF,
    bubble-border: #CCCCCC,
    cursor-bg: #000000,
);

/* Always-on aka permanent consumption */
$alwayson: (
    bg-fill: $gray,
    donut-fill: map-get($streams, 0-1),
    circle-fill: $brand-primary,
);
