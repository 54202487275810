// Registration page

.registration {

    a {
        text-decoration: underline;
    }

    ul {
      list-style-type: none;
    }

}

#btn-register:disabled {
    background: $gray40;
    border-color: $gray40;
}
